<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card p-0">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row">
                        <div class="col-12 col-md-6 p-5 login-box-img">
                            <div class="d-flex flex-column">
                                <!-- <img src="/admin_lte/logo.png" class="img-fluid px-5 pb-5" alt="Tradz.in" /> -->
                                <i class="fas fa-exclamation-circle fa-10x text-primary"></i>
                                <h1><i><br /><br /><b>{{ language.oops }}</b></i></h1>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 p-5">
                            <div class="d-flex flex-column text-center align-items-center justify-content-center h-100">
                                <h2 style="font-weight: 400;">{{ language.error_404 }}! <br><br>{{ language.could_not_found }}</h2>
                                <div class="product-buy mt-4">
                                    <router-link class="main-button" to="/">{{ language.continue_exploring }}!</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['language'],
    beforeMount(){
        document.title=this.language.page_not_found;
    },
    beforeUnmount(){
        document.title="Red Thrift";
    },
}
</script>

