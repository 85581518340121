<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-sc">
                    <button type="button" class="main-button small me-1" @click="$router.go(-1)"><i class="fas fa-arrow-left fa-lg"></i></button>
                    <h3>{{ language.my_notifications }}</h3>
                </div>
                <div class="card-body">
                    <transition-group tag="div" class="row" name="bounce" appear>
                        <Notification v-for="n in notifs" :boost="boosting" :key="n.id" :language="language" :data="n" />
                    </transition-group>
                    <NotFound :lang="language" :type="text" :load="loading" v-if="!notifs.length"></NotFound>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="modal fade" v-if="showModal" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="card-body text-center position-relative" style="height:80vh;">
                            <div class="d-inline-block position-relative h-100">
                                <img :src="modalImg" class="modal-img">
                                <button type="button" class="main-button small light close" @click="showModal = false">
                                    <i class="fas fa-times fa-15x"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.15);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
<script setup>
</script>
<script>
    export default {
        name: 'Notifications',
        props: ['language'],
        data() {
            return {
                token: this.isLogged(),
                notifs: [],
                loading: true,
                boosting: null,
                showModal: false,
                modalImg: false,
                text: this.language.loading,
            };
        },
        created() {
            if(this.token != null) this.index()
            else {
                this.loading = false
                this.text = this.language.login_to_continue
            }
            this.emitter.on('removeModal', () => {
                this.showModal = false
            })
            this.emitter.on('fetchNewNotifs', () => {
                if(this.$route.name == 'GlobalNotifications')
                this.index()
            })
        },
        methods: {
            index() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.get(this.$api + "notifications/get-notifications",config).then((res) => {
                    this.$Progress.finish()
                    this.loading = false
                    this.text = this.language.no_notifications_to_display
                    this.notifs = res.data.notifications
                    this.boosting = res.data.boosting
                }).catch((e) => {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.server_error
                });
            },
            showImageModal(id) {
                this.modalImg = this.$s3_url+this.notifs.find(item => item.id == id).my_product_details.thumbnail_image
                this.showModal = true
            }
        },
    }
</script>
