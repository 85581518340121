<template>
    <div class="row position-relative">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-ec">
                    <button type="button" class="main-button small me-2" @click="goback()"><i class="fas fa-arrow-left fa-lg"></i></button>
                    <h3 class="me-auto">
                        <router-link to="/"><i class="fas cursor-pointer fa-store me-1 me-lg-2"></i></router-link>
                        <i class="fas fa-chevron-right fa-sm me-1 me-lg-2"></i>
                        <router-link :to="(type=='group' ? '/group/i/' : (type == 'userstore' ? '/u/' : ''))+fromgroup.key" v-if="fromgroup">
                            <span v-if="!mobile" class="me-1 me-lg-2">{{ fromgroup.label }}</span>
                            <i v-else class="fas fa-users me-1 me-lg-2"></i>
                        </router-link>
                        <i v-if="fromgroup" class="fas fa-chevron-right fa-sm me-1 me-lg-2"></i>
                        <!-- <i class="fas fa-chevron-left me-1 me-lg-2" role="button" @click="$router.options.history.state.back ? $router.go(-1) : $router.push('/')"></i> -->
                        {{ language.product_details }}
                    </h3>
                    <transition-group name="fade">
                        <span data-toggle="tooltip" :title="language.prev" v-if="(p == undefined || p) && !mobile && loading == false" key="prev" class="flex-cc cursor-pointer ms-auto me-1 me-lg-2" :class="p == undefined ? 'text-muted' : 'text-primary' ">
                            <i @click="p == undefined ? '' : $router.push('/p/'+p)" class="fas fa-2x fa-chevron-circle-left"></i>
                        </span>
                        <span data-toggle="tooltip" :title="language.next" v-if="(n == undefined || n) && !mobile && loading == false" key="next" class="flex-cc cursor-pointer me-1 me-lg-2" :class="n == undefined ? 'text-muted' : 'text-primary' ">
                            <i @click="n == undefined ? '' : $router.push('/p/'+n)" class="fas fa-2x fa-chevron-circle-right"></i>
                        </span>
                        <button data-toggle="tooltip" :title="language.tooltip_likes_count" type="button" key="like" v-if="loading == false && product" @click="like_p()" class="main-button light small like me-2" :class="is_liked ? 'text-danger fw-bold' : '', !(p == undefined || p) || !(n == undefined || n) || mobile ? 'ms-auto' : ''">
                            <i class="fas fa-heart pe-1"></i> {{ numberShort(total_likes,1) }}
                        </button>
                        <button data-toggle="tooltip" :title="language.tooltip_views_count" type="button" key="views" v-if="loading == false && product?.views > 0" class="main-button me-2 small">
                            <i class="fas fa-eye pe-1"></i> {{ numberShort(product.views,1) }}
                        </button>
                        <button type="button" key="share" v-if="loading == false && product" class="main-button small" :class="{ 'me-4' : token}" @click="showModal = 'share'">
                            <i class="fas fa-share-alt" :class="{'pe-1' : !mobile}"></i>
                            <span v-if="!mobile">{{language.share}}</span>
                        </button>
                        <div v-if="!loading && token" v-click-outside="closeDropdown" class="btn-group position-absolute" key="ellipsis-dropdown">
                            <button class="btn menu-btn btn-sm d-flex" type="button" @click="navdrop = !navdrop">
                                <i class="fas fa-15x fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" :class="{ 'animate' : navdrop}">
                                <!-- <a href="javascript:;" key="chat" v-if="loading == false && product" class="dropdown-item" @click="start_message(product.id,product.thumbnail_image,product.user_details.social_profile_id,product.user_details.slug)">
                                    <i class="fas fa-comment-dots pe-1"></i> {{ language.chat }}
                                </a> -->
                                <a href="javascript:;" key="report" @click="showModal = 'report'" v-if="loading == false && product && token != null && user.user.id != product.user_details.id" class="dropdown-item">
                                    <i class="fas fa-flag me-1"></i> {{language.report}}
                                </a>
                                <router-link key="edit" v-if="token && loading == false && user && user.user && product && product.user_details.id == user.user.id" :to="'/p/update/'+product.slug" class="dropdown-item">
                                    <i class="fas fa-pencil-alt pe-lg-1"></i>
                                    <span>{{language.edit}}</span>
                                </router-link>
                                <a href="javascript:;" key="delete" v-if=" token && loading == false && user && user.user && product && product.user_details.id == user.user.id" class="dropdown-item" @click="delete_product(product.id)">
                                    <i class="fas fa-trash pe-lg-1"></i>
                                    <span>{{language.delete}}</span>
                                </a>
                            </div>
                        </div>
                    </transition-group>
                </div>
                <div class="card-body" v-if="product != []">
                    <div class="row" :class="{'match-height' : !mobile}">
                        <div class="col-12 col-lg-5">
                            <div class="px-md-4" :class="product.images.length > 1 ? 'pb-4' : ''">
                                <swiper
                                    :effect="product.images.length > 1 ? 'creative' : ''"
                                    :creativeEffect="{
                                        prev: {
                                            shadow: true,
                                            translate: [0, 0, -800],
                                            rotate: [180, 0, 0],
                                        },
                                        next: {
                                            shadow: true,
                                            translate: [0, 0, -800],
                                            rotate: [-180, 0, 0],
                                        },
                                    }"
                                    :slides-per-view="1"
                                    :keyboard="product.images.length > 1 ? { enabled: true } : false"
                                    :pagination="product.images.length > 1 ? { type: 'fraction' } : false"
                                    :autoplay="false"
                                    :navigation="true"
                                    :loop="product.images.length > 1 ? true : false"
                                    :speed="700"
                                    :autoHeight="mobile ? false : true"
                                    :modules="modules"
                                    >
                                    <!-- :navigation="true" -->
                                    <swiper-slide v-for="(img,index) in product.images" :key="index">
                                        <div class="blog-post-thumb">
                                            <img draggable="false" class="img-fluid" :src="$s3_url+img.original_path" @click="openModalOnSlide(index)">
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>
                            <div class="text-center">
                                <router-link :to="'/checkout?type=boosting&product_id='+product.crypt_id" class="main-button small mb-3" v-if="product.boosting">
                                    <i class="fas fa-bolt pe-1"></i>
                                    <span class="">{{ language.boost_this_ad }}</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7">
                            <div class="d-flex flex-column h-100 pt-lg-2">
                                <div class="d-flex justify-content-start flex-column flex-md-row my-lg-1">
                                    <h6 class="mb-2">
                                        <router-link class="hover" to="/">
                                            <u><i class="fas fa-store"></i></u>
                                        </router-link>
                                        <i v-if="product.category_details" class="fas fa-chevron-right px-2 fa-xs"></i>
                                        <router-link class="hover" :to="'/?category='+encode(product.category_id)">
                                            <u>{{ language[product.category_details?.text] }}</u>
                                        </router-link>
                                        <i v-if="product.category_details" class="fas fa-chevron-right px-2 fa-xs"></i>
                                        <router-link class="hover" :to="'/?sub_category='+encode(product.sub_category_id)">
                                            <u>{{ language[product.sub_category_details?.text] }}</u>
                                        </router-link>
                                    </h6>
                                    <div v-if="product.user_id == user?.user?.id" class="custom-control custom-switch custom-switch-lg ms-auto pb-0">
                                        <input type="checkbox" ref="trade" :checked="product.is_traded" class="custom-control-input" id="customSwitch2">
                                        <label class="custom-control-label text-nowrap" for="customSwitch2" @click="mark_item">
                                            Mark As Sold
                                        </label>
                                    </div>
                                </div>
                                <h2 class="fw-bolder product-title my-3">
                                    <i class="fas fa-star me-1 text-warning" v-if="product.is_boosted"></i>
                                    {{ product.title }}
                                </h2>
                                <div class="flex-bc pb-2">
                                    <span class="price-span text-body my-0">{{ price(product.price) }}</span>
                                    <p class="mb-0" v-if="loading == false && product">
                                        <i class="fas fa-map-marker-alt pe-1"></i> {{ product.distance }} {{  product.distance <= 1 ? 'km' : 'kms' }} {{ language.away }}
                                    </p>
                                </div>
                                <p v-if="token" class="product-description mb-1"><b>{{ language.description }}: </b>
                                    <!-- {{ product.description }} -->
                                    <transition name="fade" mode="out-in">
                                        <span :key="showingFullText">{{ formattedBody }}</span>
                                    </transition>
                                    <span v-if="product.description.split('\n').length >= 10" class="text-primary cursor-pointer" @click="showingFullText = !showingFullText"><u>Read {{ showingFullText ? "Less" : "More" }}<br><br>
                                    </u></span>
                                </p>
                                <div v-else class="my-2">
                                    <button type="button" @click="emitter.emit('showlogin')" class="main-button">
                                        {{ language.login_readmore }}
                                    </button>
                                </div>
                                <p :class="{'ms-1' : !token}" class="fs-6 text-muted mb-1" v-if="product.trade_type==1 && product.looking_for"><b>{{language.looking_for}}:</b> {{ product.looking_for }}</p>
                                <p v-if="product.mobile" :class="{'ms-1' : !token}" class="fs-6 mb-1">
                                    <b>{{ language.contact_no }}: </b> <a class="hover" :href="'tel:'+product.mobile"><u>{{ product.mobile }}</u></a>
                                </p>
                                <p :class="{'ms-1' : !token}" class="fs-6 mb-1">
                                    <b>{{ language.trade_type }}: </b>
                                    <router-link :to="'/?trade='+encode(product.trade_type)" class="fs-6 hover">
                                        <u>{{ tt_type }}</u>
                                    </router-link>
                                </p>
                                <p v-if="groups.length > 0" :class="{'ms-1' : !token}" class="mb-1">
                                    <span class="fs-6"><b>{{ language.also_posted_in }}: </b></span>
                                    <router-link :to="(g.is_joined ?  '/group/' : '/group/i/' )+g.key" v-for="(g,index) in groups" :key="index" class="hover">
                                        <u class="fs-6">{{ g.label }}</u>
                                        <b v-if="index<groups.length-1" class="fs-3 me-2">, </b>
                                    </router-link>
                                </p>
                                <p v-if="loading == false && product" :class="{'ms-1' : !token}" class="mb-1">
                                    <b>{{ language.posted_by }}: </b>
                                    <router-link :to="'/u/'+product.user_details.slug" class="fs-6 hover">
                                        <u>{{ product.user_details ? product.user_details.first_name : '' }}</u>
                                    </router-link>
                                </p>
                                <div v-if="product.user_details.id != user?.user?.id" class="flex-sc gap-2">
                                    <!-- <button v-if="loading == false && product" type="button" class="main-button small w-fit" @click="$router.push('/u/'+product.user_details.slug)">
                                        <i class="fas fa-eye pe-1 pe-lg-2"></i>{{ language.view + ' ' + (product.user_details ? product.user_details.first_name : '') + ' ' + language.ads }}
                                    </button> -->
                                    <button v-if="loading == false && product" type="button" class="main-button w-fit" @click="start_message(product.id,product.thumbnail_image,product.user_details.social_profile_id,product.user_details.slug)">
                                        <i class="fas fa-comments pe-1 pe-lg-2"></i>{{ language.chat + ' '+ language.with +' ' + (product.user_details ? product.user_details.first_name : '') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center mt-3" v-if="mobile">
                            <span v-if="(p == undefined || p)" key="prev" class="pe-1" :class="p == undefined ? 'text-muted' : 'text-primary'">
                                <i @click="p == undefined ? '' : $router.push('/p/'+p)" class="fas fa-2x fa-chevron-circle-left"></i>
                            </span>
                            <span v-if="(n == undefined || n)" key="next" class="ps-1" :class="n == undefined ? 'text-muted' : 'text-primary'">
                                <i @click="n == undefined ? '' : $router.push('/p/'+n)" class="fas fa-2x fa-chevron-circle-right"></i>
                            </span>
                        </div>
                        <div class="col-12 px-lg-5 featured-carousel" v-if="related_products.length > 0">
                            <h3 class="border-bottom pb-1 mt-5">{{ language.heading_recently_uploaded }}</h3>
                            <transition-group tag="div" class="mt-4 owl-carousel owl-features" name="bounce">
                                <Product class="mb-3" :lang="language" :user="user" :active_chats="active_chats" :product="product" :type="'?type=marketplace'" :liked_array="liked" :can_like="true" :can_del="false" v-for="(product,index) in related_products" :key="product.id" />
                            </transition-group>
                        </div>
                    </div>
                </div>
                <NotFound :lang="language" :type="text" :load="loading" v-if="!product"></NotFound>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'report'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="submit_report" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.report_user}}</h3>
                                <a href="javascript:;" @click="showModal = false; form.reset();" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12 col-lg-4">
                                        <label>{{language.report_option}}</label>
                                        <select class="form-control" v-model.number="form.radio_message_id">
                                            <!-- To be Made Dynamic -->
                                            <option value="1">{{ language.report_option_1 }}</option>
                                            <option value="2">{{ language.report_option_2 }}</option>
                                            <option value="3">{{ language.report_option_3 }}</option>
                                            <option value="4">{{ language.report_option_4 }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <label class="ms-3 mb-1">{{language.other_reason}}</label>
                                        <input type="text" v-model="form.reason" class="form-control" required autofocus>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="button" class="main-button light" @click="showModal = false; form.reset();">{{language.close}}</button>
                                <button type="submit" class="main-button ms-4">{{language.report}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'share'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.share_text }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row networks-body">
                                    <div class="col-12 mb-3">
                                        <div class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                                            <span class="link pe-md-4">{{ $domain+'/p/'+product.slug }}</span>
                                            <span class="ms-md-auto">
                                                <button type="button" class="main-button small" @click="emitter.emit('copy_url', $domain+'/p/'+product.slug)">
                                                    <i class="far fa-clipboard me-2"></i> {{ language.copy }}
                                                </button>
                                            </span>
                                        </div>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="col-3 d-flex justify-content-center" v-for="network in networks">
                                        <ShareNetwork
                                            :network="network.network"
                                            :key="network.network"
                                            :style="{backgroundColor: network.color, border: '1px solid '+network.color}"
                                            class="network-icon"
                                            :url="sharing.url"
                                            :title="sharing.title"
                                            :description="sharing.description"
                                            :quote="sharing.quote"
                                            :hashtags="sharing.hashtags"
                                            :twitterUser="sharing.twitterUser"
                                        >
                                            <i :class="network.icon"></i>
                                            <span class="ms-2" v-if="!mobile">{{ network.name }}</span>
                                        </ShareNetwork>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'image'" :class="{ 'd-block show backdrop-dark': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content bg-transparent border-0 flex-lg-row align-items-center">
                        <div class="nav-buttons text-white me-2" v-if="!mobile && product.images.length > 1">
                            <i class="fas fa-chevron-circle-left" @click="slide = (slide == 0 ? (product.images.length-1) : slide-1) ;"></i>
                        </div>
                        <div class="card-body text-center" style="height:80vh;">
                            <div class="position-relative h-100" :class="slide == index ? 'd-inline-block align-self-center' : 'd-none'" v-for="(img,index) in product.images">
                                <img draggable="false" :src="$s3_url+img.original_path" class="modal-img">
                                <button type="button" class="main-button small light close" @click="showModal = false">
                                    <i class="fas fa-times fa-15x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="nav-buttons text-white ms-2" v-if="!mobile && product.images.length > 1">
                            <i class="fas fa-chevron-circle-right" @click="slide = (slide == (product.images.length-1) ? 0 : slide+1) ;"></i>
                        </div>
                        <div class="nav-buttons text-white mt-2" v-if="mobile">
                            <i class="fas fa-chevron-circle-left me-2" @click="slide = (slide == 0 ? (product.images.length-1) : slide-1) ;"></i>
                            <i class="fas fa-chevron-circle-right ms-2" @click="slide = (slide == (product.images.length-1) ? 0 : slide+1) ;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
            margin-bottom: 60px;
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .slide-up-enter-active, .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }
    .slide-up-enter-from {
        opacity: 0;
        transform: translateY(-30px);
    }
    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(-30px);
    }

</style>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/swiper-bundle.css';
    import "swiper/css/effect-flip";
    import 'swiper/css/pagination';
    import 'swiper/css/effect-creative';
    import 'swiper/css/autoplay';
    import { EffectFlip, Autoplay, Pagination, Keyboard, EffectCreative, Navigation } from 'swiper';
    import { collection, setDoc, updateDoc, getDoc, arrayUnion, doc } from "firebase/firestore";
</script>
<script>
    export default {
        name: 'ProductView',
        props: ['user','language'],
        data() {
            return {
                token: this.isLogged(),
                slide:1,
                navdrop: false,
                product: false,
                total_likes: '',
                is_liked: '',
                liked: '',
                type: '',
                loading: true,
                docid: '',
                active_chats: [],
                groups: [],
                related_products: [],
                showingFullText: false,
                mobile: this.isMobile(),
                text: this.language.loading,
                n: false,
                p: false,
                showModal: false,
                fromgroup: false,
                fromPath: false,
                modalImg: false,
                form: new Form({
                    product_id: this.$route.params.id,
                    radio_message_id: 1,
                    reason: '',
                }),
                modules: [EffectFlip, Autoplay, Pagination, Keyboard, EffectCreative, Navigation ],
                networks: [
                    { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                    { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                    { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
                ],
                sharing: {
                    url: '',
                    title: '',
                    description: '',
                    hashtags: 'thrift,sell,trade,exchange',
                },
            };
        },
        created() {
            this.type = this.$route.query.type ;
            this.index()

            this.emitter.on("update_user", (data) => {
                if(this.$route.name == 'ProductView') {
                    this.token = this.isLogged();
                    this.index()
                }
            });
            this.emitter.on("loggedOut", () => {
                if(this.$route.name == 'ProductView') {
                    this.token = null
                    this.index()
                }
            });
            this.emitter.on('removeModal', () => {
                this.showModal = false
            })

            if(window.history.length > 0 && this.type) {
                this.emitter.emit('get-np', {type: this.type, id: this.$route.params.id })
            }

            this.emitter.on('set-np', (e) => {
                this.n = (e.n == undefined ? undefined : e.n+'?type='+this.type+(this.type=='group' ? '&id='+this.$route.query.id : ''))
                this.p = (e.p == undefined ? undefined : e.p+'?type='+this.type+(this.type=='group' ? '&id='+this.$route.query.id : ''))
            })
        },
        beforeMount() {
            this.emitter.off('set-np')
            this.emitter.off('set-gnp')
        },
        updated() {
            if(!this.mobile) $('[data-toggle="tooltip"]').tooltip()
        },
        beforeMount(){
            document.title="RedThrift - Product";
            $('head').append('<link rel="canonical" href="'+this.$domain+this.$route.fullPath+'" />')
            $('[data-toggle="tooltip"]').tooltip('dispose')
        },
        computed: {
             tt_type() {
                if(this.product.trade_type == 1) return this.language.trade.split('/')[0] ;
                else if(this.product.trade_type == 2) return this.language.sell ;
                else if(this.product.trade_type == 3) return this.language.free ;
                else if(this.product.trade_type == 4) return this.language.rent ;
                else if(this.product.trade_type == 5) return this.language.buy ;
                else if(this.product.trade_type == 6) return this.language.service ;
                else return 'Sale' ;
            },
            tt_class() {
                if(this.product.trade_type == 1) return 'bg-orange' ;
                else if(this.product.trade_type == 2) return 'bg-white-b' ;
                else if(this.product.trade_type == 3) return 'bg-green' ;
                else if(this.product.trade_type == 4) return 'bg-purple' ;
                else if(this.product.trade_type == 5) return 'bg-yellow' ;
                else if(this.product.trade_type == 6) return 'bg-red' ;
                else return 'bg-white-b' ;
            },
            sources() {
                return this.product.images.map(img => {
                    return this.$s3_url+img.original_path ;
                }) ;
            },
            formattedBody() {
                if (this.showingFullText || this.product.description.split('\n').length < 10) {
                    return `${this.product.description} `;
                }
                return `${this.product.description.split('\n').slice(0, 10).join('\n').trim()}... `;
            },
        },
        beforeUnmount() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
        },
        methods: {
            index() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.get(this.$api + "products/get-product/"+this.$route.params.id+'/user',config).then((res) => {
                    if(res.data.status === false) {
                        this.$Progress.fail()
                        this.loading = false
                        this.text = this.language.product_not_found
                        this.fromgroup = res.data.group_name
                    }
                    else {
                        this.product = res.data.data
                        this.related_products = res.data.related_products
                        this.active_chats = res.data.active_chats
                        this.liked = res.data.liked_items
                        this.is_liked = res.data.is_liked
                        this.groups = res.data.groups
                        this.total_likes = res.data.data.total_likes_count
                        this.loading = false
                        if(this.type == 'group') {
                            var temp = this.$route.query.id
                            this.fromgroup = this.groups.find(i => i.key == temp)
                        }
                        else if(this.type == 'userstore') {
                            this.fromgroup = {
                                key: this.product.user_details.slug,
                                label: this.product.user_details?.store?.store_name.length > 0 ? this.product.user_details.store.store_name : this.product.user_details.first_name,
                            }
                        }

                        this.text = this.language.no_products_to_display
                        this.sharing.url =  this.$domain+'/p/'+this.$route.params.id
                        this.sharing.title = `${this.product.title}\n`
                        // this.sharing.description = `${this.language.share_text}\n`
                        this.sharing.description = ''
                        this.$Progress.finish()
                        $('head meta[name="description"]').remove()
                        $('head meta[name="title"]').remove()
                        $('head').prepend('<meta name="og:description" content="'+this.product.title+'" />')
                        $('head').prepend('<meta name="og:title" content="Red Thrift - Don\'t trash it, encash it!" />')
                        $('head').prepend('<meta name="og:image" content="'+this.$s3_url+this.product.images[0].original_path+'" />')

                        this.$nextTick(() => {
                            let loop = this.related_products.length > (this.mobile ? 2 : 5) ? true : false
                            $('.owl-features').owlCarousel({
                                items: 5,
                                slideBy: 5,
                                stagePadding: 10,
                                loop: loop,
                                dots: false,
                                nav: true,
                                autoplay: false,
                                autoplayTimeout: 4000,
                                margin: 20,
                                responsive: {
                                    0: {
                                        items: 2,
                                        autoplay: true,
                                    },
                                    576: {
                                        items: 3
                                    },
                                    991: {
                                        items:4
                                    },
                                    1200: {
                                        items: 5
                                    },
                                }
                            })
                        })
                    }
                }).catch((e) => {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.server_error
                });
            },
            async start_message(product_id,image_blob,product_user_spi,slug){
                if(this.token == null){
                    // if(!this.mobile) {
                    //     toast.fire({
                    //         icon: "info",
                    //         title: this.language.login_to_continue,
                    //     });
                    // }
                    // else {
                        this.emitter.emit('showlogin')
                    // }
                    return ;
                }
                if(product_user_spi != this.user.user.social_profile_id) {
                    this.$Progress.start()
                    // if(this.active_chats.includes(product_id)){
                    //     this.$Progress.finish()
                    //     toast.fire({
                    //         icon: "info",
                    //         title: this.language.toast_chat_already_active,
                    //     });
                    // }else{
                        // if(this.user.user_products_count == 0){
                        //     Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 4000 })
                        //     .fire({ icon: "info", title: this.language.toast_first_add_products_to_message });
                        //     return false
                        // }
                        var fd = new FormData()
                        fd.append('first_user',this.user.user.social_profile_id)
                        fd.append('second_user',product_user_spi)
                        fd.append('product_id',product_id)
                        var config = { headers: { Authorization: `Bearer ${this.token}` } }
                        axios.post(this.$api + "chats/create-chat",fd,config).then(async (res)=>{
                            if(res.data.status == true){
                                var chk = doc(db, "messages", this.user.user.social_profile_id+"-"+product_user_spi);

                                var docSnap =  await getDoc(chk);
                                this.docid = ''
                                if (docSnap.exists()) {
                                    this.docid = this.user.user.social_profile_id+"-"+product_user_spi;
                                } else {
                                    this.docid = product_user_spi+"-"+this.user.user.social_profile_id
                                }

                                const docRef = doc(db, "messages", this.docid);

                                var str  = Date.now().toString() ;
                                var msg = this.language.new_chat_message ;
                                setDoc(docRef, {
                                    'recentMessage': msg,
                                    'timestamp' : str,
                                    'isDisabled': false,
                                    'blocked_by' : '',
                                })

                                const colRef = collection(docRef, this.docid)
                                setDoc(doc(colRef, str), {
                                    "idFrom" : this.user.user.social_profile_id,
                                    "idTo" : product_user_spi,
                                    "content" : msg,
                                    "imageBlob" : image_blob,
                                    "timestamp" : str,
                                    "type" : 0,
                                    "seen" : 0,
                                })

                                var chat_data = {
                                    user: product_user_spi,
                                    user_crypt: slug,
                                    product_id: product_id,
                                    chat_id: this.docid,
                                }

                                await updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                                    "chats": arrayUnion(chat_data)
                                })

                                var rev_chat_data = {
                                    user: this.user.user.social_profile_id,
                                    user_crypt: this.user.user.slug,
                                    product_id: product_id,
                                    chat_id: this.docid,
                                }

                                await updateDoc(doc(db, 'users', product_user_spi), {
                                    "chats": arrayUnion(rev_chat_data)
                                })

                                this.$Progress.finish()
                                toast.fire({
                                    'icon' : 'success',
                                    'title': this.language.toast_message_sent,
                                })
                                this.$router.push('/chats')
                            }
                        })
                        .catch((error)=>{
                            this.$Progress.fail()
                        })
                    // }
                }
            },
            mark_item() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                var fd = new FormData()
                this.$Progress.start()
                if(!this.product.is_traded) {
                    axios.get(this.$api+'products/make-traded/'+this.product.id,config).then((response) => {
                        if(response.data.status){
                            toast.fire({
                                icon: "success",
                                title: this.language.toast_marked_traded,
                            });
                            this.$Progress.finish()
                            this.product.is_traded = true
                        }
                    }).catch((e) => {
                        this.$refs.trade.click() ;
                        this.$Progress.fail()
                    });;
                }
                else {
                    axios.get(this.$api+'products/make-untraded/'+this.product.id,config).then((response) => {
                        if(response.data.status){
                            toast.fire({
                                icon: "error",
                                title: this.language.toast_item_not_traded,
                            });
                            this.$Progress.finish()
                            // this.index() ;
                            this.product.is_traded = false
                        }
                    }).catch((e) => {
                        this.$refs.trade.click() ;
                        this.$Progress.fail()
                    });;
                }
            },
            share() {
                if (navigator.share) {
                    navigator.share({
                        title: this.product.title,
                        text: this.language.share_text,
                        url: this.$domain+'/p/'+this.product.slug,
                    })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
                }
            },
            like_p() {
                if(this.token == null){
                    this.emitter.emit('showlogin')
                    return ;
                }
                // if(!this.can) {
                    var config = { headers: { Authorization: `Bearer ${this.token}` } }
                    var fd = new FormData()
                    fd.append('liked_product_id',this.product.id)
                    fd.append('message_text',this.language.notification_text)
                    this.$Progress.start()
                    if(!this.is_liked) {
                        axios.post(this.$api+'like/make-like',fd,config).then((response) => {
                            if(response.data.status){
                                this.$Progress.finish()
                                this.is_liked = true
                                this.total_likes++ ;
                            }
                        }).catch((e) => {
                            this.$Progress.fail()
                        });
                    }
                    else {
                        axios.post(this.$api+'like/make-unlike',{ 'liked_product_id':this.product.id },config).then((response) => {
                            if(response.data.status){
                                this.$Progress.finish()
                                this.is_liked = false
                                this.total_likes-- ;
                            }
                        }).catch((e) => {
                            this.$Progress.fail()
                        });;
                    }
                // }
            },
            delete_product(id) {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                var thiss = this
                Swal.fire({
                    title: this.language.toast_are_you_sure,
                    text: this.language.toast_wont_able_to_revert,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.language.toast_yes_delete,
                    customClass: {
                        confirmButton: 'main-button',
                        cancelButton: 'main-button bg-danger ms-3'
                    },
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        thiss.$Progress.start()
                        axios.get(thiss.$api+'products/remove-product/'+id,config).then((response) => {
                            if(response.data.status){
                                thiss.$Progress.finish()
                                Swal.fire({
                                    icon: 'success',
                                    title: thiss.language.deleted,
                                    text: thiss.language.toast_product_deleted_success,
                                    customClass: {
                                        confirmButton: 'btn btn-success'
                                    }
                                });
                                thiss.emitter.emit('get_user')
                                thiss.$router.push('/store')
                            }
                        }).catch((e) => {
                            thiss.$Progress.fail()
                            toast.fire({
                                icon: "error",
                                title: thiss.language.server_error,
                            });
                        });
                    }
                });

            },
            submit_report() {
                if(this.token == null){
                    toast.fire({
                        icon: "info",
                        title: this.language.login_to_continue,
                    });
                    return
                }
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.post(this.$api + "report/report-product",this.form,config).then(async (res) => {
                    this.$Progress.finish()
                    await this.report_firebase()
                    this.$nextTick(() => {
                        toast.fire({
                            icon: "success",
                            title: this.language.toast_product_user_reported,
                        });
                        this.$router.push('/')
                    })
                }).catch((e) => {
                    toast.fire({
                        icon: "error",
                        title: this.language.toast_error_occured,
                    });
                    this.$Progress.fail()
                });;
            },
            showImageModal(index) {
                this.modalImg = this.$s3_url+this.product.images[index].original_path
                this.showModal = 'image'
            },
            openModalOnSlide: function(number) {
				this.slide = number;
                this.showModal = 'image'
			},
            price(val) {
                var formatter = new Intl.NumberFormat();
                return this.product.user_details.currency_symbol+' '+formatter.format(val);
            },
            async report_firebase() {
                var chk = doc(db, "messages", this.product.user_details.social_profile_id+"-"+this.user.user.social_profile_id);
                var docSnap =  await getDoc(chk);
                this.docid = ''
                if (docSnap.exists()) {
                    this.docid = this.product.user_details.social_profile_id+"-"+this.user.user.social_profile_id;
                } else {
                    this.docid = this.user.user.social_profile_id+"-"+this.product.user_details.social_profile_id
                }
                var docRef = doc(db, "messages", this.docid);
                updateDoc(docRef, {
                    "isReported": true,
                    "reported_by" : this.user.user.social_profile_id,
                    "reported_on": Date.now().toString(),
                })
            },
            closeDropdown() {
                this.navdrop = false
            },
            goback() {
                window.history.state.back ? this.$router.go(-1) : this.$router.push('/')
            },
        },
    }
</script>
