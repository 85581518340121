<template>
    <div class="row mt-3 match-height">
        <button @click.prevent="fireBaseusers">Create Users</button>
    </div>
</template>

<script setup>
    import { getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
    import { setDoc, doc } from "firebase/firestore";
</script>
<script>
export default {
    name: 'FirebaseUser',
    data() {
        return {
            users:[]
        };
    },

    mounted() {
        // setDoc(doc(db, 'users', getAuth().currentUser.uid), {
        //         "Email" : this.form.email,
        //         "assetImage" : this.form.email,
        //         "blocked_by" : [],
        //         "blocked_usr" : [],
        //         "chattingWith" : null,
        //         "createdAt" :Date.now().toString(),
        //         "groups" : ['abc'],
        //         "id" : getAuth().currentUser.uid,
        //         "imageBlob" : res.data.user_img,
        //         "nickname" : response.user.displayName,
        //         "photoUrl" : response.user.photoURL,
        //         "pushToken" : '',
        //     })
        axios.get(this.$api + "get-users", this.form).then((res) => {
           this.users = res.data.users

            }).catch((e) => { this.$Progress.fail() ; console.log(e) ; });
    },

    methods: {
        async fireBaseusers() {
            this.$Progress.start();
            console.log("fireBaseusers started");

            this.users.forEach((user) =>{
                console.log(user);
                setDoc(doc(db, 'users', user.social_profile_id), {
                    "Email" : user.email,
                    "assetImage" : user.email,
                    "blocked_by" : [],
                    "blocked_usr" : [],
                    "chattingWith" : null,
                    "createdAt" :Date.now().toString(),
                    "groups" : ['abc'],
                    "id" : user.social_profile_id,
                    "imageBlob" : user.thumbnail_image,
                    "nickname" : user.fullname,
                    "photoUrl" : user.thumbnail_image,
                    "pushToken" : '',
                })
                console.log(user.fullname+' Created'+ user.id);
            })
        }
    },
}
</script>
