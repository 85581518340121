<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <ul class="social">
                        <li><a href="#" aria-label="Facebook"><i class="fab fa-facebook"></i></a></li>
                        <li><a href="#" aria-label="Whatsapp"><i class="fab fa-whatsapp"></i></a></li>
                        <li><a href="#" aria-label="Instagram"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" aria-label="Twitter"><i class="fab fa-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <p class="copyright">{{ language.copyright_text }} &copy; {{ year }} REDTHRIFT.COM</p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'f_footer',
    props: ['language'],
    data() {
        return {
            year: '2023',
        };
    },
    created() {
        var d = new Date() ;
        this.year = d.getFullYear() ;
    },
}
</script>