<template>
    <div class="row position-relative" v-swipe="{ left: onSwipeLeft, right: onSwipeRight}">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-ec">
                    <h3 class="me-auto">
                        <router-link to="/"><i class="fas cursor-pointer fa-store me-1 me-lg-2"></i></router-link>
                        <i class="fas fa-chevron-right fa-sm me-1 me-lg-2"></i>
                        {{ language.product_details }}
                    </h3>
                    <transition-group name="fade">
                        <button type="button" key="approve" v-if="loading == false && product && !product.approved && !product.rejection" @click="setStatus('approved')" class="main-button small ms-auto me-2 bg-success">
                            <i class="fas fa-check-double pe-1"></i> {{ language.approve }}
                        </button>
                        <button type="button" key="reject" v-if="loading == false && product && !product.approved && !product.rejection" @click="rejectModal()" class="main-button me-2 small bg-danger-button">
                            <i class="fas fa-times pe-1"></i> {{ language.reject }}
                        </button>
                        <button type="button" key="approve" v-if="loading == false && product && product.approved && !product.rejection" class="main-button small ms-auto me-2 bg-success">
                            <i class="fas fa-check-double pe-1"></i> Already Approved
                        </button>
                        <button type="button" key="reject" v-if="loading == false && product && !product.approved && product.rejection" class="main-button me-2 small bg-danger-button">
                            <i class="fas fa-times pe-1"></i> Already Rejected
                        </button>
                    </transition-group>
                </div>
                <div class="card-body" v-if="product != []">
                    <div class="row" :class="{'match-height' : !mobile}">
                        <div class="col-12 col-lg-5">
                            <div class="px-md-4" :class="product.images.length > 1 ? 'pb-4' : ''">
                                <swiper
                                    :effect="product.images.length > 1 ? 'creative' : ''"
                                    :creativeEffect="{
                                        prev: {
                                            shadow: true,
                                            translate: [0, 0, -800],
                                            rotate: [180, 0, 0],
                                        },
                                        next: {
                                            shadow: true,
                                            translate: [0, 0, -800],
                                            rotate: [-180, 0, 0],
                                        },
                                    }"
                                    :slides-per-view="1"
                                    :keyboard="product.images.length > 1 ? { enabled: true } : false"
                                    :pagination="product.images.length > 1 ? { type: 'fraction' } : false"
                                    :autoplay="false"
                                    :navigation="mobile ? false : true"
                                    :loop="product.images.length > 1 ? true : false"
                                    :speed="700"
                                    :autoHeight="mobile ? false : true"
                                    :modules="modules"
                                    >
                                    <!-- :navigation="true" -->
                                    <swiper-slide v-for="(img,index) in product.images" :key="index">
                                        <div class="blog-post-thumb">
                                            <!-- <div class="ribbon-wrapper"><div class="ribbon" :class="tt_class">{{ tt_type }}</div></div> -->
                                            <img draggable="false" class="img-fluid" :src="$s3_url+img.original_path" @click="openModalOnSlide(index)">
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7">
                            <div class="d-flex flex-column h-100 pt-lg-2">
                                <h2 class="fw-bolder product-title">{{ product.title }}</h2>
                                <p class="text-lg-end mb-2" v-if="loading == false && product">
                                    <i class="fas fa-map-marker-alt pe-1"></i> {{ product.distance }} {{  product.distance <= 1 ? 'km' : 'kms' }} {{ language.away }}
                                </p>
                                <div class="d-flex flex-column flex-md-row justify-content-between my-lg-1">
                                    <h6 class="mb-2">
                                        <router-link class="hover" to="/">
                                            <u><i class="fas fa-store"></i></u>
                                        </router-link>
                                        <i v-if="product.category_details" class="fas fa-chevron-right px-2 fa-xs"></i>
                                        <router-link class="hover" :to="'/?category='+encode(product.category_id)">
                                            <u>{{ language[product.category_details?.text] }}</u>
                                        </router-link>
                                        <i v-if="product.category_details" class="fas fa-chevron-right px-2 fa-xs"></i>
                                        <router-link class="hover" :to="'/?sub_category='+encode(product.sub_category_id)">
                                            <u>{{ language[product.sub_category_details?.text] }}</u>
                                        </router-link>
                                    </h6>
                                    <div v-if="product.user_id == user?.user?.id" class="custom-control custom-switch custom-switch-lg ms-5">
                                        <input type="checkbox" ref="trade" :checked="product.is_traded" class="custom-control-input" id="customSwitch2">
                                        <label class="custom-control-label text-nowrap" for="customSwitch2" @click="mark_item">
                                            Mark As Sold
                                        </label>
                                    </div>
                                </div>
                                <span class="price-span text-body">{{ price(product.price) }}</span>
                                <div class="d-flex">
                                    <!-- <router-link :to="'/u/'+product.user_details.slug" v-if="loading == false && product" class="main-button small light me-2">
                                        <img :src="$s3_url+product.user_details.thumbnail_image" class="profile-icon">
                                        {{ product.user_details ? product.user_details.first_name : '' }}
                                    </router-link> -->
                                    <div class="col-6 col-md-3">
                                        <div class="group-block xs-small" v-if="loading == false && product">
                                            <img @click="$router.push('/u/'+product.user_details.slug)" :src="$s3_url+product.user_details.thumbnail_image" class="profile-icon w30">
                                            <div @click="$router.push('/u/'+product.user_details.slug)" class="name text-body">
                                                {{ product.user_details ? product.user_details.first_name : '' }}
                                            </div>
                                            <button type="button" class="main-button small" @click="start_message(product.id,product.thumbnail_image,product.user_details.social_profile_id,product.user_details.slug)">
                                                <i class="fas fa-comments"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <p class="fs-6 mb-3 mt-2">{{ product.description }}</p>
                                <p class="fs-6 text-muted" v-if="product.trade_type==1 && product.looking_for"><b>{{language.looking_for}}:</b> {{ product.looking_for }}</p>
                                <p class="fs-6">
                                    <b>{{ language.trade_type }}: </b> 
                                    <router-link :to="'/?trade='+encode(product.trade_type)" class="fs-6 hover">
                                        <u>{{ tt_type }}</u>
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <NotFound :lang="language" :type="text" :load="loading" v-if="!product"></NotFound>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'image'" :class="{ 'd-block show backdrop-dark': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content bg-transparent border-0 flex-lg-row align-items-center">
                        <div class="nav-buttons text-white me-2" v-if="!mobile && product.images.length > 1">
                            <i class="fas fa-chevron-circle-left" @click="slide = (slide == 0 ? (product.images.length-1) : slide-1) ;"></i>
                        </div>
                        <div class="card-body text-center" style="height:80vh;">
                            <div class="position-relative h-100" :class="slide == index ? 'd-inline-block align-self-center' : 'd-none'" v-for="(img,index) in product.images">
                                <img draggable="false" :src="$s3_url+img.original_path" class="modal-img">
                                <button type="button" class="main-button small light close" @click="showModal = false">
                                    <i class="fas fa-times fa-15x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="nav-buttons text-white ms-2" v-if="!mobile && product.images.length > 1">
                            <i class="fas fa-chevron-circle-right" @click="slide = (slide == (product.images.length-1) ? 0 : slide+1) ;"></i>
                        </div>
                        <div class="nav-buttons text-white mt-2" v-if="mobile">
                            <i class="fas fa-chevron-circle-left me-2" @click="slide = (slide == 0 ? (product.images.length-1) : slide-1) ;"></i>
                            <i class="fas fa-chevron-circle-right ms-2" @click="slide = (slide == (product.images.length-1) ? 0 : slide+1) ;"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'reject'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="setStatus('rejected')" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.reject}}</h3>
                                <a href="javascript:;" @click="showModal = false; remarks = '';" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{language.remarks}}</label>
                                        <textarea class="form-control" ref="reject_remarks" rows="2" v-model="remarks" autofocus></textarea>
                                    </div>
                                    <!-- <div class="col-12 col-lg-8">
                                        <label class="ms-3 mb-1">{{language.other_reason}}</label>
                                        <input type="text" v-model="form.reason" class="form-control" required autofocus>
                                    </div> -->
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="button" class="main-button light" @click="showModal = false; remarks = '';">{{language.close}}</button>
                                <button type="submit" class="main-button ms-4">{{language.submit}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
            margin-bottom: 60px;
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .slide-up-enter-active, .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }
    .slide-up-enter-from {
        opacity: 0;
        transform: translateY(-30px);
    }
    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(-30px);
    }

</style>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/swiper-bundle.css';
    import "swiper/css/effect-flip";
    import 'swiper/css/pagination';
    import 'swiper/css/effect-creative';
    import 'swiper/css/autoplay';
    import { EffectFlip, Autoplay, Pagination, Keyboard, EffectCreative, Navigation } from 'swiper';
</script>
<script>
    export default {
        name: 'ProductView',
        props: ['user','language'],
        data() {
            return {
                token: this.isLogged(),
                slide:1,
                navdrop: false,
                product: false,
                total_likes: '',
                is_liked: '',
                liked: '',
                type: '',
                remarks: '',
                loading: true,
                docid: '',
                active_chats: [],
                mobile: this.isMobile(),
                text: this.language.loading,
                n: false,
                p: false,
                showModal: false,
                fromgroup: false,
                fromPath: false,
                modalImg: false,
                form: new Form({
                    product_id: this.$route.params.id,
                    radio_message_id: 1,
                    reason: '',
                }),
                modules: [EffectFlip, Autoplay, Pagination, Keyboard, EffectCreative, Navigation ],
                networks: [
                    { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                    { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                    { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
                ],
                sharing: {
                    url: '',
                    title: '',
                    description: '',
                    hashtags: 'thrift,sell,trade,exchange',
                },
            };
        },
        created() {
            this.type = this.$route.query.type ;
            this.index()

            this.emitter.on("update_user", (data) => {
                if(this.$route.name == 'ProductView') {
                    this.token = this.isLogged();
                    this.index()
                }
            });
            this.emitter.on("loggedOut", () => {
                if(this.$route.name == 'ProductView') {
                    this.token = null
                    this.index()
                }
            });
            this.emitter.on('removeModal', () => {
                this.showModal = false
            })

            if(window.history.length > 0 && this.type) {
                this.emitter.emit('get-np', {type: this.type, id: this.$route.params.id })
            }

            this.emitter.on('set-np', (e) => {
                this.n = (e.n == undefined ? undefined : e.n+'?type='+this.type+(this.type=='group' ? '&id='+this.$route.query.id : ''))
                this.p = (e.p == undefined ? undefined : e.p+'?type='+this.type+(this.type=='group' ? '&id='+this.$route.query.id : ''))
            })
        },
        beforeMount() {
            this.emitter.off('set-np')
            this.emitter.off('set-gnp')
        },
        updated() {
            if(!this.mobile) $('[data-toggle="tooltip"]').tooltip()
        },
        beforeMount(){
            document.title="RedThrift - Product";
            $('head').append('<link rel="canonical" href="'+this.$domain+this.$route.fullPath+'" />')
            $('[data-toggle="tooltip"]').tooltip('dispose')
        },
        computed: {
             tt_type() {
                if(this.product.trade_type == 1) return this.language.trade.split('/')[0] ;
                else if(this.product.trade_type == 2) return this.language.sell ;
                else if(this.product.trade_type == 3) return this.language.free ;
                else if(this.product.trade_type == 4) return this.language.rent ;
                else if(this.product.trade_type == 5) return this.language.buy ;
                else if(this.product.trade_type == 6) return this.language.service ;
                else return 'Sale' ;
            },
            tt_class() {
                if(this.product.trade_type == 1) return 'bg-orange' ;
                else if(this.product.trade_type == 2) return 'bg-white-b' ;
                else if(this.product.trade_type == 3) return 'bg-green' ;
                else if(this.product.trade_type == 4) return 'bg-purple' ;
                else if(this.product.trade_type == 5) return 'bg-yellow' ;
                else if(this.product.trade_type == 6) return 'bg-red' ;
                else return 'bg-white-b' ;
            },
            sources() {
                return this.product.images.map(img => {
                    return this.$s3_url+img.original_path ;
                }) ;
            },
        },
        beforeUnmount() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
        },
        methods: {
            index() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.get(this.$api + "products/get-product/"+this.$route.params.id+'/admin',config).then((res) => {
                    if(res.data.status === false) {
                        this.$Progress.fail()
                        this.loading = false
                        this.text = this.language.product_not_found
                        this.fromgroup = res.data.group_name
                    }
                    else {
                        this.product = res.data.data
                        this.active_chats = res.data.active_chats
                        this.liked = res.data.liked_items
                        this.is_liked = res.data.is_liked
                        this.total_likes = res.data.data.total_likes_count
                        this.loading = false
                        this.text = this.language.no_products_to_display
                        this.$Progress.finish()
                    }
                }).catch((e) => {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.server_error
                });
            },
            setStatus(type) {
                axios.post(this.$api+'admin/make-ad', {id: this.product.crypt_id, status: type, remarks: this.remarks}).then((response) => {
                    toast.fire({
                        icon: "success",
                        title: type == 'approved' ? this.language.toast_approved : this.language.toast_rejected,
                    });
                    if(type != 'approved') this.showModal = false
                    if(response.data.id)
                        this.$router.push('/view-ad/admin/'+response.data.id)
                    else {
                        this.$router.push('/')   
                    }
                    this.$Progress.finish()
                }).catch((e) => { this.$Progress.fail() });
            },
            showImageModal(index) {
                this.modalImg = this.$s3_url+this.product.images[index].original_path
                this.showModal = 'image'
            },
            openModalOnSlide: function(number) {
				this.slide = number;
                this.showModal = 'image'
			},
            price(val) {
                var formatter = new Intl.NumberFormat();
                return this.product.user_details.currency_symbol+' '+formatter.format(val);
            },
            onSwipeLeft() {
                if(this.p) this.$router.push('/p/'+this.p)
            },
            onSwipeRight() {
                if(this.n) this.$router.push('/p/'+this.n)
            },
            rejectModal() {
                this.showModal = 'reject'
                this.$nextTick(() => {
                    this.$refs.reject_remarks.focus()
                })
            }
        },
    }
</script>
