<template>
    <div class="row">
        <transition-group name="flip">
            <div class="col-12 col-lg-5" v-if="!mobile || (mobile && !flip)">
                <div class="features-card contact-form" :class="{'min-sc' : !mobile}">
                    <div class="card-header flex-bc">
                        <h3>{{ language.chats }}</h3>
                        <input v-if="token" type="search" v-model="search_chat" class="form-control w-auto mb-0 small ms-auto" :placeholder="language.search_chat">
                    </div>
                    <!-- style="height:400px"  -->
                    <div class="card-body chat_container row py-1" v-if="filter_users_chats.length">
                        <transition-group tag="div" name="bounce">
                            <div class="col-12" v-for="g in filter_users_chats" :key="g.id">
                                <div @click="openchat(g)" class="group-block small mb-3">
                                    <img :src="$s3_url+g.imageBlob" class="profile-icon w40">
                                    <div class="d-flex flex-column ms-1">
                                        <div class="name text-body">
                                            <span :class="{ 'text-muted' : g.isReported}">{{ g.nickname }}</span>
                                            <i data-toggle="tooltip" :title="'Banned'" class="fas fa-ban ms-2 text-danger" v-if="g.isReported"></i>
                                        </div>
                                        <div class="message ms-0 fw-bold" v-if="r_arr.find(item => item.id == g.id) != null">
                                            <div v-if="r_arr.find(item => item.id == g.id).unseen_count > 0">{{r_arr.find(item => item.id == g.id).unseen_count + ' ' + language.new_messages}}</div>
                                            <div class="text-muted" v-else>{{r_arr.find(item => item.id == g.id).recentMessage}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    <NotFound :lang="language" :type="text" :load="loading" v-else></NotFound>
                </div>
            </div>
            <div class="col-12 col-lg-7 position-relative" v-if="!mobile || (mobile && flip)">
                <div class="features-card min-sc d-flex sticky align-items-center justify-content-center flex-column pb-2">
                    <div class="w-100 card-header flex-bc" v-if="is_active">
                        <h3 class="d-inline-flex mb-0 align-items-center">
                            <i role="button" @click="close_chat()" class="fas fa-chevron-left pe-lg-2"></i>
                            <img :src="$s3_url+current_chat_user.imageBlob" class="profile-icon w40 pointer" @click="gotonext(current_chat_user.id)">
                            <div class="pointer" @click="gotonext(current_chat_user.id)">{{ current_chat_user.nickname }}</div>
                        </h3>
                        <div class="d-flex justify-content-end">
                            <button v-if="mobile" type="button" class="main-button small bg-primary me-2" @click="close_chat()">{{ language.chats }}</button>
                            <transition name="fade" v-if="!current_chat_user.isReported">
                                <button type="button" class="main-button small bg-danger" v-if="r_arr.find(item => item.id == current_chat_user.id).isDisabled == false" @click="ban_user(current_chat_user.id)">{{ language.block }}</button>
                                <button class="main-button small bg-dark" v-else-if="r_arr.find(item => item.id == current_chat_user.id).blocked_by == user.user.social_profile_id" type="button" @click="unban_user(current_chat_user.id)">{{ language.unblock }}</button>
                            </transition>
                            <transition name="fade" v-else>
                                <button type="button" class="main-button small not-allowed" disabled>{{ language.reported }}</button>
                            </transition>
                        </div>
                    </div>
                    <div class="w-100 card-body" v-if="is_active">
                        <transition-group tag="div" class="mb-3 chat_container" style="height:calc(100vh - 270px)" name="fade">
                            <div class="text-center" v-if="msg_loading">
                                <i class="fas fa-spinner fa-spin fa-3x my-3 text-primary"></i>
                            </div>
                            <div v-for="(c,index) in chat_reverse" :class="{ 'reverse' : user.user.social_profile_id==c.idFrom }" :key="c.timestamp" class="msg-container">
                                <div class="d-flex flex-column" :class="{ 'align-items-end' : user.user.social_profile_id==c.idFrom }">
                                    <div class="msgImg" v-if="c.imageBlob!=null" :class="user.user.social_profile_id==c.idFrom ? 'ms-0 me-auto justify-content-end' : 'justify-content-start'">
                                        <img :src="$s3_url+c.imageBlob" draggable="false" class="img-fluid">
                                    </div>
                                    <div class="msgbox" :class="[{ 'incoming' : user.user.social_profile_id==c.idFrom },index < chat.length - 1 && chat[index+1].idFrom==c.idFrom ? 'mb-2p' : '']">
                                        <span class="msg">{{c.content}}</span>
                                    </div>
                                </div>
                                <span v-if="!mobile" class="time" :class="user.user.social_profile_id==c.idFrom ? 'me-3' : 'ms-3'">{{$filters.formatUnix(c.timestamp)}}</span>
                            </div>
                            <NotFound :lang="language" :type="ctext" :load="cloading" class="mb-5" v-if="!chat.length"></NotFound>
                        </transition-group>
                        <transition name="fade">
                            <div id="sendmessage" :class="{'mx-0 shadow rounded-4' : mobile}" v-if="!current_chat_user.isReported && current_chat_user !='' && r_arr.find(item => item.id == current_chat_user.id).isDisabled == false">
                                <input type="text" class="ps-2 rounded-3" @keypress.enter="send_message()" :placeholder="language.send_message + '...'" v-model="msg_to_send" />
                                <button @click="send_message()" class="rounded-3"><i class="fab fa-telegram-plane"></i></button>
                            </div>
                            <div v-else-if="!current_chat_user.isReported && !(current_chat_user !='' && r_arr.find(item => item.id == current_chat_user.id).isDisabled == false)" class="ban py-2" id="sendmessage">
                                <h3 class="mb-0 text-center" v-if="r_arr.find(item => item.id == current_chat_user.id).blocked_by == user.user.social_profile_id">{{ language.unblock_to_continue }}</h3>
                                <h3 class="mb-0 text-center" v-else>{{ language.you_cant_reply }}</h3>
                            </div>
                            <div v-else-if="current_chat_user.isReported" class="ban py-2" id="sendmessage">
                                <h3 class="mb-0 text-center" v-if="r_arr.find(item => item.id == current_chat_user.id).reported_by == user.user.social_profile_id">{{ language.you_have_reported }}</h3>
                                <h3 class="mb-0 text-center" v-else>{{ language.you_have_been_reported }}</h3>
                            </div>
                        </transition>
                    </div>
                    <NotFound :lang="language" :type="ctext" :load="cloading" class="mb-5" v-else></NotFound>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.4s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.3s reverse;
    }
    .fade-enter-active {
        transition: all 0.3s ease-in;
    }
    .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    .flip-enter-active {
        transition: all 0.5s ease;
    }
    .flip-leave-active {
        display: none;
    }
    .flip-enter-from,.flip-leave-to {
        transform: rotateY(180deg);
        opacity: 0;
    }

</style>
<script setup>
    import { collection, query , onSnapshot, orderBy, getDocs, where, startAfter, setDoc, updateDoc, getDoc, limit, doc} from "firebase/firestore";
</script>
<script>
export default {
    name: "Chats",
    props: ["user",'language'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            loading: true,
            text: this.language.loading,
            msg_to_send: "",
            is_active: false,
            flip: false,
            name: "",
            docid: "",
            chat: [],
            chat_reverse: [],
            active_chats: [],
            users_chats: [],
            is_private: false,
            currentUser: null,
            cloading: false,
            msg_loading: false,
            last_limit: null,
            ctext: this.language.select_chat,
            current_chat_user:'',
            errors: [],
            search_chat: '',
            r_arr: [],
            config: {
                headers: {
                Authorization: `Bearer ${localStorage.getItem("greta")}`,
                },
            },
        };
    },
    created() {
        if (this.token != null){
            this.index()
        }
        else {
            this.loading = false;
            this.text = this.language.login_to_continue
        }
        this.emitter.on("update_user", (data) => {
            if(this.$route.name == 'Chats') {
                this.token = this.isLogged();
                this.index(data.user.social_profile_id)
            }
        });
        this.emitter.on("loggedOut", () => {
            if(this.$route.name == 'Chats') {
                this.token = null
                this.is_active = false
                this.users_chats = []
            }
        });
        this.scrollToBottom()
    },
    beforeUnmount() {
        var elements = document.getElementsByClassName('msg_container') ;
        for (var i = 0; i < elements.length; i++) {
            elements[i].removeEventListener('scroll', this.chat_scroll);
        }
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
        this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable') )
        this.scrollToBottom(true)
    },
    computed: {
        filter_users_chats() {
            if(this.search_chat) {
                return this.users_chats.map((item, i) => Object.assign({}, item, this.r_arr[i])).sort((a, b) => b.timestamp - a.timestamp).filter(item => {
                    return item.nickname.toUpperCase().includes(this.search_chat.toUpperCase())
                })
            }
            else {
                return this.users_chats.map((item, i) => Object.assign({}, item, this.r_arr[i])).sort((a, b) => b.timestamp - a.timestamp);
            }
        },
        filter_length: function() {
            return this.filter_users_chats.length ;
        }
    },
    watch: {
        filter_length: {
            handler: function (n,o) {
                if(n>0 && n == this.users_chats.length) {
                    this.loading = false
                    this.text = this.language.no_chats_to_display;
                }
            },
        }
    },
    methods: {
        index(spid= null) {
            if(spid == null) spid = this.user.user.social_profile_id
            this.loading = true
            this.text = this.language.loading
            const q = doc(db, "users", spid);
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                if(querySnapshot.data()) {
                    this.active_chats = querySnapshot.data()['chats']
                    if(this.active_chats != undefined && this.active_chats.length > 0) {
                        this.active_chats.forEach( async (element, key) => {
                            const docRef =  doc(db, "users", element.user);
                            const docSnap =  await getDoc(docRef);

                            var chk3 = doc(db, "messages", element.chat_id);
                            const docSnap3 =  await getDoc(chk3);

                            //Getting unseen count Start
                            var q = query(collection(chk3, element.chat_id), where("seen", "==", 0));
                            const qs = await getDocs(q);
                            var count = 0;
                            qs.forEach((ele) => {
                                if(ele.data().idFrom != spid){
                                    count = count + 1
                                }
                            });

                            //Getting unseen count End
                            var d = docSnap3.data();
                            var dd = Object.assign({}, d)
                            dd['id'] = element.user
                            dd['unseen_count'] = count
                            if(!this.r_arr.some(item => item.id == element.user)) {
                                this.r_arr.push(dd);
                            }
                            else {
                                var index = this.r_arr.findIndex(function(item) { return item.id == spid });
                                this.r_arr[index] = dd
                            }
                            var checkid = docSnap.data()?.id ;
                            if(checkid && !this.users_chats.some(item => item.id == checkid)) this.users_chats.push(docSnap.data());

                            if(checkid && key == this.active_chats.length - 1) this.openchat(docSnap.data())
                        })
                    }
                    else {
                        this.loading = false
                        this.text = this.language.no_chats_to_display
                    }
                }
                else {
                    this.loading = false
                    this.text = this.language.no_chats_to_display
                }
            });
        },
        async openchat(g) {
            this.flip = true;

            this.current_chat_user = g
            this.cloading = true,
            this.ctext =  this.language.loading,
            this.$Progress.start();
            var chk = query(doc(db, "messages", g.id+"-"+this.user.user.social_profile_id)) ;
            var docSnap =  await getDoc(chk);
            this.docid = ''
            if (docSnap.exists()) {
                this.docdata = docSnap.data();
                this.docid = g.id+"-"+this.user.user.social_profile_id;
            } else {
                this.docdata = ''
                this.docid = this.user.user.social_profile_id+"-"+g.id
            }
            var docdata;
            var docRef = doc(db, "messages", this.docid);
            var colRef = query(collection(docRef, this.docid), orderBy("timestamp","desc"), limit(100))

            const unsubscribe = onSnapshot(colRef, (querySnapshot) => {
                this.chat = []
                var index = 0 ;
                if(querySnapshot.docs.length) {
                    querySnapshot.forEach((doc) => {
                        docdata = doc.data();
                        this.chat.push(docdata);
                        if(index == 0) {
                            this.r_arr.find(item => item.id == this.current_chat_user.id).recentMessage = docdata.content
                        }
                        index++ ;
                    });
                    this.last_limit = querySnapshot.docs[querySnapshot.docs.length-1];
                    this.$nextTick(() => {

                        var elements = document.getElementsByClassName('msg_container') ;
                        for (var i = 0; i < elements.length; i++) {
                            elements[i].addEventListener('scroll', this.chat_scroll);
                        }

                        this.scrollToBottom(true)
                    })
                }
                else {
                    this.cloading = false
                    this.ctext = this.language.no_chats_to_display
                }
                this.chat_reverse = this.chat.reverse() ;
            });
            this.seen_messages(g)
            this.$Progress.finish();
            this.is_active = true;
        },
        async seen_messages(g = null){
            var docRef = doc(db, "messages", this.docid);
            var colRef = collection(docRef, this.docid)

            var q = query(collection(docRef, this.docid), where("seen", "==", 0));
            // var q2 = q.where("idfrom", "!=", this.user.user.social_profile_id);
            const qs = await getDocs(q);
            qs.forEach((ele) => {
                if(ele.data().idFrom != this.user.user.social_profile_id){
                    updateDoc(doc(colRef, ele.data().timestamp), {
                        "seen" : 1
                    })
                }

            });
            if(g) {
                this.r_arr.find(item => item.id == g.id).unseen_count = 0
            }
        },
        send_message(){
            if(this.msg_to_send == '') return

            const docRef = doc(db, "messages", this.docid);
            const colRef = collection(docRef, this.docid)

            var str  = Date.now().toString() ;
            setDoc(doc(colRef, str), {
                "idFrom" : this.user.user.social_profile_id,
                "idTo" : this.current_chat_user.id,
                "content" : this.msg_to_send,
                "imageBlob" : null,
                "timestamp" : str,
                "type" : 0,
                "seen" : 0
            })
            setDoc(docRef, {
                'recentMessage': this.msg_to_send,
                "timestamp" : str,
                'isDisabled': false,
                'blocked_by' : '',
            })

            this.r_arr.find(item => item.id == this.current_chat_user.id).recentMessage = this.msg_to_send
            this.r_arr.find(item => item.id == this.current_chat_user.id).timestamp = str
            this.msg_to_send = ''

            this.seen_messages()
        },
        close_chat(){
            this.is_active = false
            this.cloading = false
            this.flip = false
            this.chat = []
            this.ctext = this.language.select_chat
            this.r_arr.find(item => item.id == this.current_chat_user.id).unseen_count = 0
        },
        gotonext(id){
            var slug = this.active_chats.find(item => item.user == id).user_crypt
            this.$router.push({ path: '/u/'+slug })
        },
        async ban_user(id) {
            this.$Progress.start()
            let fd = new FormData();
            fd.append('social_profile_id', id);
            fd.append('reason', 'abc');
            this.$Progress.start()
            axios.post(this.$api + "block/block-user",fd,this.config).then(async (res)=>{
                if(res.data.status == true){
                    var chk = doc(db, "messages", id+"-"+this.user.user.social_profile_id);
                    var docSnap =  await getDoc(chk);
                    this.docid = ''
                    if (docSnap.exists()) {
                        this.docid = id+"-"+this.user.user.social_profile_id;
                    } else {
                        this.docid = this.user.user.social_profile_id+"-"+id
                    }
                    var docRef = doc(db, "messages", this.docid);
                    updateDoc(docRef, {
                        'isDisabled': true,
                        "blocked_by" : this.user.user.social_profile_id,
                    })
                    toast.fire({
                        'icon': 'success',
                        'title': this.language.toast_user_blocked,
                    })
                    // this.is_active = false
                    // this.active_chats = []
                    // this.users_chats = []
                    // this.index()
                    this.r_arr.find(item => item.id == id).isDisabled = true
                    this.r_arr.find(item => item.id == id).blocked_by = this.user.user.social_profile_id
                }
            }).catch((err)=>{
                this.$Progress.fail()
            })
            this.$Progress.finish()
        },
        async unban_user(id) {
            this.$Progress.start()
            axios.get(this.$api + "block/unblock-user/"+id,this.config).then(async (res)=>{
                if(res.data.status == true){
                    var chk = doc(db, "messages", id+"-"+this.user.user.social_profile_id);
                    var docSnap =  await getDoc(chk);
                    this.docid = ''
                    if (docSnap.exists()) {
                        this.docid = id+"-"+this.user.user.social_profile_id;
                    } else {
                        this.docid = this.user.user.social_profile_id+"-"+id
                    }
                    var docRef = doc(db, "messages", this.docid);
                    updateDoc(docRef, {
                        'isDisabled': false,
                        "blocked_by" : '',
                    })
                    toast.fire({
                        'icon': 'success',
                        'title': this.language.toast_user_unblocked,
                    })
                    // this.is_active = false
                    // this.active_chats = []
                    // this.users_chats = []
                    // this.index()
                    this.r_arr.find(item => item.id == id).isDisabled = false
                    this.r_arr.find(item => item.id == id).blocked_by = ''
                    this.$Progress.finish()
                }
            }).catch((err)=>{
                this.$Progress.fail()
            })
        },
        async chat_scroll(e) {
            // console.log($(e.target).innerHeight());
            if($(e.target).scrollTop()==0) {
                this.msg_loading = true;
                var docRef = doc(db, "messages", this.docid);
                var q = query(collection(docRef, this.docid), orderBy("timestamp","desc"), startAfter(this.last_limit), limit(100))
                var docSnap =  await getDocs(q);
                if(docSnap.docs.length > 0) {
                    docSnap.forEach((data) => {
                        this.chat_reverse.unshift(data.data());
                    })
                    this.last_limit = docSnap.docs[docSnap.docs.length-1];
                }
                this.msg_loading = false;
            }
        },
        scrollToBottom(flag) {
            var ele = $('.chat_container .msg-container:last-child') ;
            if(ele.length > 0) {
                if(!this.mobile)
                    $('.chat_container .msg-container:last-child')[0].scrollIntoView({ behavior: "smooth", block: "end" });
                else {
                    setTimeout(() => {
                        $('.chat_container .msg-container:last-child')[0].scrollIntoView({ behavior: "smooth", block: "end" });
                    }, 100);
                    if(flag) {
                        var ele = $('#sendmessage') ;
                        if(ele.length > 0)
                            $('#sendmessage')[0].scrollIntoView({behavior: 'smooth', block: 'end'});
                    }

                }
            }
        }
    },
};
</script>
