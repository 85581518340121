<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card p-0">
                <transition name="fade">
                    <div class="card-body" v-if="!loading && plan">
                        <div class="row" :class="{ 'match-height' : !mobile}">
                            <div class="col-12" v-if="mobile">
                                <div class="checkout-box rounded-bottom-0">
                                    <div class="checkout-top" v-if="type=='new-ads'">
                                        <i class="fas fa-exclamation-circle text-warning fa-2x"></i>
                                        <h4>{{ this.language.checkout_title_new_ads }}</h4>
                                        <h5>{{ this.language.checkout_subtitle_new_ads }}</h5>
                                        <span class="plan">{{ plan.name }}</span>
                                    </div>
                                    <div class="checkout-top" v-else>
                                        <i class="fas fa-bolt text-warning fa-2x"></i>
                                        <h4>{{ this.language.checkout_title_boosting }}</h4>
                                        <h5>{{ this.language.checkout_subtitle_boosting }}</h5>
                                        <span class="plan">{{ plan.name }}</span>
                                    </div>
                                    <div class="checkout-body">
                                        <div class="description mb-auto">{{ plan.description }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="p-3 p-lg-5">
                                    <div class="sticky">
                                        <Product v-if="type!='new-ads'" :lang="language" :user="user" :product="product" :type="'?type=myads'" :liked_array="[]" :can_like="true" :can_del="true" :display="'list'" />
                                        <span v-if="type=='new-ads'" class="config-heading">{{ language.label_duration }}</span>
                                        <div v-if="type=='new-ads'" class="config-blocks">
                                            <div class="block" v-for="(i,index) in durations" :key="index">
                                                <input type="radio" :value="i" v-model="s_duration" :id="'s_duration_'+index">
                                                <label :for="'s_duration_'+index">{{ i+' '+( i <= 1 ? this.language.month : this.language.months) }}</label>
                                            </div>
                                        </div>
                                        <span v-if="type=='new-ads'" class="config-heading">{{ language.label_ad_no }}</span>
                                        <div v-if="type=='new-ads'" class="config-blocks">
                                            <div class="block" v-for="(i,index) in ads" :key="index">
                                                <input type="radio" :checked="i == s_ad" :value="i" v-model="s_ad" :id="'s_ad_'+index">
                                                <label :for="'s_ad_'+index">{{ i+' '+( i <= 1 ? this.language.ad : this.language.ads) }}</label>
                                            </div>
                                        </div>
                                        <span v-if="type!='new-ads'" class="config-heading">{{ language.label_ad_days }}</span>
                                        <div v-if="type!='new-ads'" class="config-blocks">
                                            <div class="block" v-for="(i,index) in durations" :key="index">
                                                <input type="radio" :checked="i == s_duration" :value="i" v-model="s_duration" :id="'s_duration_'+index">
                                                <label :for="'s_duration_'+index">{{ i+' '+( i <= 1 ? this.language.days : this.language.days) }}</label>
                                            </div>
                                        </div>
                                        <span class="config-heading">{{ language.label_payment_method }}</span>
                                        <div class="config-blocks gap-4">
                                            <div class="block image" v-for="(i,index) in payments" :key="index">
                                                <input type="radio" :checked="i == s_pay" :value="index" v-model="s_pay" :id="'s_pay_'+index">
                                                <label :for="'s_pay_'+index">
                                                    <img src="/admin_lte/payu_logo.png" :class="Object.entries(payments).length > 2 ? 'p-2' : ''" v-if="index == 'payu'" alt="payu">
                                                    <img src="/admin_lte/paypal_logo.png" :class="Object.entries(payments).length > 2 ? 'p-2' : ''" v-if="index == 'paypal'" alt="paypal">
                                                    <img src="/admin_lte/razorpay_logo.png" v-if="index == 'razorpay'" alt="razorpay">
                                                    <img src="/admin_lte/stripe_logo.png" v-if="index == 'stripe'" alt="stripe">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="p-3 p-lg-5" :class="mobile ? 'pt-0' : ''">
                                    <div class="checkout-box">
                                        <div class="checkout-top" v-if="!mobile && type=='new-ads'">
                                            <i class="fas fa-exclamation-circle text-warning fa-2x"></i>
                                            <h4>{{ this.language.checkout_title_new_ads }}</h4>
                                            <h5>{{ this.language.checkout_subtitle_new_ads }}</h5>
                                            <span class="plan">{{ plan.name }}</span>
                                        </div>
                                        <div class="checkout-top" v-else-if="!mobile && type == 'boosting'">
                                            <i class="fas fa-bolt text-warning fa-2x"></i>
                                            <h4>{{ this.language.checkout_title_boosting }}</h4>
                                            <h5>{{ this.language.checkout_subtitle_boosting }}</h5>
                                            <span class="plan">{{ plan.name }}</span>
                                        </div>
                                        <div class="checkout-body" v-if="!mobile">
                                            <div class="description mb-auto">{{ plan.description }}</div>
                                        </div>
                                        <div class="checkout-footer">
                                            <div class="prices" v-if="type=='new-ads'">
                                                Duration
                                                <transition name="fade" mode="out-in">
                                                    <span :key="s_duration">{{ s_duration ? ( s_duration+' '+( s_duration <= 1 ? this.language.month : this.language.months)) : 'N/A' }}</span>
                                                </transition>
                                            </div>
                                            <div class="prices" v-if="type=='new-ads'">
                                                Ads to Post
                                                <transition name="fade" mode="out-in">
                                                    <span :key="s_ad">{{ s_ad ? ( s_ad+' '+( s_ad <= 1 ? this.language.ad : this.language.ads)) : 'N/A' }}</span>
                                                </transition>
                                            </div>
                                            <div class="prices" v-if="type=='boosting'">
                                                Days to Promote
                                                <transition name="fade" mode="out-in">
                                                    <span :key="s_duration">{{ s_duration ? ( s_duration+' '+( s_duration <= 1 ? this.language.days : this.language.days)) : 'N/A' }}</span>
                                                </transition>
                                            </div>
                                            <div class="prices">
                                                Payment Method
                                                <transition name="fade" mode="out-in">
                                                    <span :key="s_pay" class="text-uppercase">{{ s_pay || 'N/A' }}</span>
                                                </transition>
                                            </div>
                                            <div class="prices">
                                                {{ language.label_subtotal}}
                                                <transition name="fade" mode="out-in">
                                                    <span :key="pricing">{{ pricing || 'N/A' }}</span>
                                                </transition>
                                            </div>
                                            <div class="prices">
                                                {{ language.label_tax_amount }} <span>{{ price(0) }}</span>
                                            </div>
                                            <div class="total">
                                                {{ language.label_total }}
                                                <transition name="fade" mode="out-in">
                                                    <span :key="pricing">{{ pricing || 'N/A' }}</span>
                                                </transition>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button @click.prevent="createOrder()" :disabled="buttonLoading" type="button" class="main-button pay-button">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading"></i>
                                                </transition>
                                                {{ language.pay_now }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NotFound :lang="language" class="pb-4" :type="text" :load="loading" v-else></NotFound>
                </transition>
            </div>
        </div>
        <div class="col-md-12 row d-none">
            <div v-if="checkoutFinalData != null && checkoutFinalData['gateway'] == 'razorpay'" class="razorpay">
                <form method="POST" :action="checkoutFinalData['checkoutUrl']">
                    <input type="hidden" name="key_id" :value="checkoutFinalData['key']"/>
                    <input type="hidden" name="amount" :value="checkoutFinalData['amount']"/>
                    <input type="hidden" name="order_id" :value="checkoutFinalData['id']"/>
                    <input type="hidden" name="name" value="Redthrift"/>
                    <input type="hidden" name="description" value="Ads Purchase on Redthrift.com"/>
                    <input type="hidden" name="image" :value="$domain+'/redthrift.png'"/>
                    <input type="hidden" name="prefill[name]" :value="user.user.first_name+' '+user.user.first_name"/>
                    <input type="hidden" name="prefill[email]" :value="user.user.email"/>
                    <input type="hidden" name="callback_url" :value="$domain+'/payment-success-razorpay'"/>
                    <input type="hidden" name="cancel_url" :value="$domain+'/payment-cancel-razorpay?order_id='+checkoutFinalData['id']"/>
                    <button id="razorpaySubmit">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.4s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.2s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        20% {
            filter: blur(2px);
        }
        50% {
            transform: scale(1.03);
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        filter: blur(3px);
        opacity: 0.5;
    }
</style>
<script>
export default {
    name: "Checkout",
    props: ['user','categories', 'language'],
    components: {},
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            type: '',
            product_id: '',
            loading: false,
            buttonLoading: false,
            paypal_Currencies: ['AUD','BRL','CAD','CNY','CZK','DKK','EUR','HKD','HUF','ILS','JPY','MYR','MXN','TWD','NZD','NOK','PHP','PLN','GBP','SGD','SEK','CHF','THB','USD'],
            text: '',
            plan: '',
            product: [],
            configs: [],
            ads: [],
            durations: [],
            s_duration: '',
            s_ad: '',
            s_pay: '',
            s_currency: '',
            payments: '',
            checkoutFinalData : null
        };
    },
    computed: {
        pricing() {
            var formatter = new Intl.NumberFormat();
            if(this.type == 'new-ads')
                var price = this.configs?.find(c => c.duration == this.s_duration && c.ads == this.s_ad)?.amount
            else
                var price = this.configs?.find(c => c.days == this.s_duration)?.amount
            return price ? this.user.user.currency_symbol + ' ' + formatter.format(price) : false;
        }
    },
    created() {
        this.type = this.$route.query.type

        if(this.type == 'new-ads' && this.user.can_post) this.$router.push('/p/add')
        else {
            if(this.type == 'boosting') this.product_id = this.$route.query.product_id
            this.index()
        }
    },
    watch: {
        '$route': function (newVal, oldVal) {
            if(newVal.name == 'Checkout') {
                this.type = this.$route.query.type
    
                if(this.type == 'new-ads' && this.user.can_post) this.$router.push('/p/add')
                else {
                    if(this.type == 'boosting') this.product_id = this.$route.query.product_id
                    this.index()
                }
            }
        }
    },
    methods: {
        async index() {
            this.loading = true
            this.text = this.language.loading

            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            await axios.get(this.$api + "checkout/get-data"+(this.type == 'boosting' ? '/'+this.product_id : '')+"?type="+this.type, config).then((res) => {

                this.loading = false
                this.$Progress.finish()

                if(res.data.status === 'failed') {
                    if(res.data.type == 'plan') this.text = this.language.checkout_error_plan
                    else if(res.data.type == 'product') this.text = this.language.checkout_error_product
                    else this.text = this.language.checkout_error_product_boosted
                    
                    setTimeout(() => { this.$router.push('/store') }, 2000);
                    return ;
                }

                this.payments = res.data.payments
                this.product = res.data.product
                if(this.payments.hasOwnProperty('paypal') && !this.paypal_Currencies.includes(this.user.user.currency_code))
                    delete this.payments['paypal']
            
                this.plan = res.data.plan
                this.configs = this.plan.configs.filter(c => c.currency == this.user.user.currency_code);
                if (this.configs?.length == 0) {
                  this.configs = this.plan.configs.filter(c => c.currency == 'INR');
                  this.s_currency = 'INR'
                } else {
                  this.s_currency = this.user.user.currency_code
                }

                if(this.type == 'new-ads') {
                    this.durations = this.plan.selecteds.durations
                    this.ads = this.plan.selecteds.ads
                }
                else {
                    this.durations = this.plan.selecteds.days
                }
            });
        },
        async createOrder() {

            if(this.type == 'new-ads' && (!this.s_currency || !this.s_ad || !this.s_duration || !this.s_pay)) {
                toast.fire({icon: 'info', title: this.language.toast_select_options});
                return ;
            }
            else if(this.type != 'new-ads' && (!this.s_currency || !this.s_duration || !this.s_pay)) {
                toast.fire({icon: 'info', title: this.language.toast_select_options});
                return ;
            }

            this.buttonLoading = true
            toast.fire({icon: 'info', title: this.language.loading})
            this.$Progress.start()

            if(this.type == 'new-ads') {
                var data = {
                    'amount' : this.configs?.find(c => c.currency == this.s_currency && c.duration == this.s_duration && c.ads == this.s_ad)?.amount,
                    'currency' : this.s_currency,
                    'ads' : this.s_ad,
                    'duration' : this.s_duration,
                    'gateway' : this.s_pay,
                    'type' : this.type
                }
            }
            else {
                var data = {
                    'amount' : this.configs?.find(c => c.currency == this.s_currency && c.days == this.s_duration)?.amount,
                    'currency' : this.s_currency,
                    'days' : this.s_duration,
                    'product' : this.product_id,
                    'gateway' : this.s_pay,
                    'type' : this.type
                }
            }

            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + "checkout/create-order", data, config).then((res) => {

                if(res.data.status == 'failed') {
                    toast.fire({icon: 'error', title: this.language.server_error })
                    return ;
                }

                this.$Progress.finish()

                if(this.s_pay == 'razorpay') {
                    this.checkoutFinalData = res.data.order
                    setTimeout(function() {
                        $('#'+res.data.order['gateway']+'Submit').trigger('click');
                    }, 1000);
                }
                else if(['stripe','paypal'].includes(this.s_pay)) {
                    window.location.href = res.data.url
                } 
            }).catch((err) => { this.buttonLoading = false; this.$Progress.fail() });
        },
        price(val) {
            var formatter = new Intl.NumberFormat();
            return this.user.user.currency_symbol + ' ' + formatter.format(val);
        }
    },
};
</script>
