<template>
    <div ref="block" class="col-md-4 col-12">
        <router-link v-if="!view_only" :to="'/group/'+group.groupId" class="group-block small">
            <div class="me-1 me-md-2">
                <div class="group-icon position-relative" v-if="!group.icon || group.icon == ''">
                    {{ Array.from(group.groupName)[0] }}
                    <span v-if="g_data.some(item => (item.firebase_group_id == group.groupId && item.requests_count))" class="notif fs-6 left invert faa-tada animated">
                        {{ g_data.find(item => item.firebase_group_id == group.groupId).requests_count }}
                    </span>
                </div>
                <img v-else :src="$s3_url + group.icon" class="profile-icon w50">
            </div>
            <div class="body">
                <div class="content">
                    <div class="name" ref="name" :data-toggle="isEllipsisActive ? 'tooltip' : ''" :title="isEllipsisActive ? group.groupName : ''">{{ group.groupName }}</div>
                    <i data-toggle="tooltip" :title="language.admin" class="fas fa-user-shield ms-2" v-if="group.admins.includes(user.user.social_profile_id)"></i>
                    <i data-toggle="tooltip" :title="language.private_group" class="fas fa-lock ms-2" v-if="group.is_private"></i>
                </div>
                <div class="message" v-if="group.recentMessage == ''">{{language.no_message }}</div>
                <div class="message" v-else>{{ group.recentMessageSender +' : '+group.recentMessage  }}</div>
            </div>
        </router-link>
        <div v-else class="group-block small" @click="$router.push('/group/i/'+group.firebase_group_id)">
            <div class="me-1 me-md-1">
                <div class="group-icon" v-if="!group.icon_blob || group.icon_blob == ''">{{ Array.from(group.name)[0] }}</div>
                <img v-else :src="$s3_url + group.icon_blob" class="profile-icon w50">
            </div>
            <div class="body">
                <div class="content">
                    <div ref="name" class="name">{{ group.name }}</div>
                    <i data-toggle="tooltip" :title="language.private_group" class="fas fa-lock ms-2" v-if="group.is_private"></i>
                </div>
                <div class="message">
                    {{ group.group_distance }} 
                    {{  user?.user?.distance_unit == 'mi' ? 'mi' : (group.group_distance <= 1 ? 'km' : 'kms') }} 
                    {{ language.away }}
                    <span v-if="group.loc_label">{{ ' - '+group.loc_label }}</span>
                </div>
            </div>
            <!-- <router-link :to="'/group/i/'+group.firebase_group_id" class="main-button text-nowrap small">{{ language.view }}</router-link> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'GroupBlock',
    props: ['group','language','user','g_data','view_only'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("greta")}`,
                },
            },
            isEllipsisActive: false,
            block: null,
        };
    },
    mounted() {
        if(this.$refs.block.classList.contains('last_item')) {
            this.block = this.$refs.block
            this.checkItem()
        }
        this.checkEllipsisOverflow();
        this.$nextTick(() => {
            if(!this.mobile)
            $('[data-toggle="tooltip"]').tooltip()
        })
        window.addEventListener('resize', this.checkEllipsisOverflow);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkEllipsisOverflow);
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    methods: {
        checkEllipsisOverflow() {
            const titleElement = this.$refs.name;
            const containerWidth = titleElement.clientWidth;
            
            const clone = titleElement.cloneNode(true);
            clone.style.visibility = 'hidden';
            clone.style.position = 'absolute';
            clone.style.width = 'auto';
            clone.style.maxWidth = 'none';
            document.body.appendChild(clone);

            this.isEllipsisActive = clone.offsetWidth > containerWidth;
            document.body.removeChild(clone);
        },
        checkItem() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.emitter.emit('view_more')
                    }
                });
            });
            observer.observe(this.block);
        },
    },
}
</script>