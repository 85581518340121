<template>
    <div class="col-12">
        <!-- {{ data }} -->
        <div class="notification-box" :class="{ 'unseen' : !data.is_seen }" @click="notifAction">
            <div>
                <div class="icon" v-if="['invite_accepted','request_accepted','group_invite','welcome','request_join', 'new_ad_boost','rejected_ad','like'].includes(data.type) || data?.group_details?.icon_blob">
                    <img v-if="['new_ad_boost','rejected_ad','like'].includes(data.type)" :src="$s3_url+data?.product_details?.thumbnail_image" alt="">
                    <img v-else-if="['invite_accepted','request_accepted','group_invite', 'request_join'].includes(data.type)" :src="$s3_url + data?.group_details?.icon_blob" class="profile-icon w50">
                    <img v-else :src="'/redthriftm.png'">
                </div>
                <div class="group-icon" v-else>
                    {{ Array.from(data?.group_details?.name)[0] }}
                </div>
            </div>
            <div class="content">
                <div class="title" v-html="notif_title"></div>
                <div class="timestamp">{{ $filters.formatDate(data.created_at) }}</div>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'invite' " :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.view_group_invites}}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body p-0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="group-block small">
                                            <div class="me-2">
                                                <div class="group-icon position-relative" v-if="!data?.group_details?.icon_blob || data?.group_details?.icon_blob == ''">
                                                    {{ Array.from(data?.group_details?.name)[0] }}
                                                </div>
                                                <img v-else :src="$s3_url + data?.group_details?.icon_blob" class="profile-icon w50">
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="name ms-2">{{ data?.group_details?.name }}</div>
                                                <div class="message ms-2">{{ language.admin }}: {{ data?.group_details?.admins[0].user_details.fullname }}</div>
                                            </div>
                                            <button type="button" :disabled="buttonLoading=='accept' || buttonLoading=='reject'" @click="accept_invite(data?.group_details?.firebase_group_id, data.for_user_id)" class="main-button small ms-auto me-2">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='accept'|| buttonLoading=='reject'"></i>
                                                </transition>
                                                <i class="fas fa-check"></i>
                                            </button>
                                            <button type="button" :disabled="buttonLoading=='accept' || buttonLoading=='reject'" @click="reject_invite(data?.group_details?.firebase_group_id, data.for_user_id)" class="main-button small bg-dark">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='reject' || buttonLoading=='accept'"></i>
                                                </transition>
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'request'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div
                                class="card-header flex-bc">
                                <h3>{{ language.group_join_requests }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body p-0">
                                <div class="group-block small">
                                    <img :src="data?.user_details ? $s3_url + data?.user_details?.thumbnail_image : ''" class="profile-icon w40">
                                    <div class="name">
                                        {{ data?.user_details?.first_name }}
                                    </div>
                                    <button type="button" :disabled="buttonLoading=='approve_request' || buttonLoading=='reject_request'" class="main-button ms-5 ms-md-auto me-2 small" @click="approve(data.from_user_id, data.user_details?.social_profile_id)">
                                        <transition name="fade">
                                            <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='approve_request' || buttonLoading=='reject_request'"></i>
                                        </transition>
                                        <i class="fas fa-check"></i>
                                    </button>
                                    <button type="button" :disabled="buttonLoading=='approve_request' || buttonLoading=='reject_request'" class="main-button small bg-dark" @click="reject(data.from_user_id)">
                                        <transition name="fade">
                                            <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='approve_request' || buttonLoading=='reject_request'"></i>
                                        </transition>
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
<script>
import { updateDoc, arrayUnion, doc } from "firebase/firestore";
export default {
    props: ["language", "data", 'boost'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            showModal: false,
            buttonLoading: false,
        };
    },
    mounted() {
        if(!this.mobile)
        $('[data-toggle="tooltip"]').tooltip()
    },
    beforeUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    computed: {
        notif_title() {
            var string = '' ;
            switch(this.data.type) {
                case 'welcome':
                    string = this.language.notif_welcome;
                    string = string.replace('%u_user_name%', '<b>'+this.data.own_user.first_name+'</b>')
                    string = string.replace('%appname%', '<b>'+this.$appname+'</b>')
                break ;
                case 'invite_accepted':
                    string = this.language.notif_invite_accepted;
                    string = string.replace('%u_user_name%', '<b>'+this.data.user_details.first_name+'</b>')
                    string = string.replace('%g_group_name%', '<b>'+this.data.group_details.name+'</b>')
                break ;
                case 'request_accepted':
                    string = this.language.notif_request_accepted;
                    string = string.replace('%g_group_name%', '<b>'+this.data.group_details.name+'</b>')
                break ;
                case 'group_invite':
                    string = this.language.notif_group_invite;
                    string = string.replace('%u_user_name%', '<b>'+this.data.user_details.first_name+'</b>')
                    string = string.replace('%g_group_name%', '<b>'+this.data.group_details.name+'</b>')
                break ;
                case 'request_join':
                    string = this.language.notif_request_join;
                    string = string.replace('%u_user_name%', '<b>'+this.data.user_details.first_name+'</b>')
                    string = string.replace('%g_group_name%', '<b>'+this.data.group_details.name+'</b>')
                break ;
                case 'new_ad_boost': string = this.language.notif_new_ad_boost; break ;
                case 'rejected_ad': string = this.language.notif_rejected_ad; break ;
                case 'like':
                    string = this.language.notif_like;
                    string = string.replace('%u_user_name%', '<b>'+this.data.user_details.first_name+'</b>')
                    string = string.replace('%p_product_name%', '<b>'+this.data.product_details.title+'</b>')
                break ;
                default: break ;
            }

            return string;
        }
    },
    methods: {
        notifAction() {
            switch (this.data.type) {
                case 'welcome': this.$router.push('/p/add') ; break;
                case 'invite_accepted': this.$router.push('/group/'+this.data?.group_details?.firebase_group_id) ; break;
                case 'request_accepted': this.$router.push('/group/'+this.data?.group_details?.firebase_group_id) ; break;
                case 'group_invite': {
                    if (this.data.action_taken) {
                        this.$router.push('/group/'+this.data?.group_details?.firebase_group_id)
                    } else {
                        this.showModal = 'invite' ; break;
                    }
                }
                case 'request_join': {
                    if (this.data.action_taken) {
                        this.$router.push('/group/'+this.data?.group_details?.firebase_group_id)
                    } else {
                        this.showModal = 'request' ; break;
                    }
                }
                case 'new_ad_boost': 
                    if(this.boost)
                        this.$router.push('/checkout?type=boosting&product_id='+this.data.product_details.crypt_id) ; 
                    else
                        this.$router.push('/store')
                    break;
                case 'rejected_ad': this.$router.push('/store') ; break;
                case 'like': this.$router.push('/u/'+this.data.user_details.slug) ; break;
                default: return ; break;
            }
        },
        async accept_invite(id,user_id) {
            this.buttonLoading = 'accept'
            this.$Progress.start() ;
            await updateDoc(doc(db, 'groups', id), {
                "members": arrayUnion(this.data.own_user.social_profile_id)
            })
            await updateDoc(doc(db, 'users', this.data.own_user.social_profile_id), {
                "groups": arrayUnion(id)
            })
            var fd = {
                firebase_group_id: id,
                user_ids: [user_id],
            }
            await this.markActionComplete()
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/add-members',fd,config).then((res) => {
                this.$Progress.finish() ;
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_group_joined_success,
                })
                // this.index()
                this.$router.push('/group/'+id)
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail() ;
            })
            this.buttonLoading = false
        },
        async reject_invite(id,user_id) {
            this.buttonLoading = 'reject'
            this.$Progress.start() ;
            var fd = {
                firebase_group_id: id,
                user_ids: [user_id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await this.markActionComplete()
            await axios.post(this.$api + 'groups/delete-invites',fd,config).then((res) => {
                this.$Progress.finish() ;
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_discarded,
                })
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail() ;
            })
            this.buttonLoading = false
        },

        async approve(id, uid) {
            this.buttonLoading = 'approve_request'
            await updateDoc(doc(db, 'groups', this.data.group_details.firebase_group_id), {
                "members": arrayUnion(uid)
            })
            await updateDoc(doc(db, 'users', uid), {
                "groups": arrayUnion(this.data.group_details.firebase_group_id)
            })
            this.$Progress.start();
            var fd = {
                firebase_group_id: this.data.group_details.firebase_group_id,
                user_ids: [id],
            }
            await this.markActionComplete()
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/approve-requests', fd, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_accepted,
                })
                this.$router.push('/group/'+this.data.group_details.firebase_group_id)
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
            })
            this.buttonLoading = false
        },
        async reject(id) {
            this.buttonLoading = 'reject_request'
            this.$Progress.start();
            var fd = {
                firebase_group_id: this.data.group_details.firebase_group_id,
                user_ids: [id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await this.markActionComplete()
            await axios.post(this.$api + 'groups/delete-requests', fd, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_rejected,
                })
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
            })
            this.buttonLoading = false
        },

        async markActionComplete() {
            this.$Progress.start() ;
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.get(this.$api + 'notifications/mark-complete/'+this.data.id,config).then((res) => {
            }).catch((e) => {
                this.$Progress.fail() ;
            })
        },
    }
}
</script>
