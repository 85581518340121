<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card contact-form">
                <div class="card-header flex-sc">
                    <button type="button" class="main-button small me-1" @click="$router.go(-1)"><i class="fas fa-arrow-left fa-lg"></i></button>
                    <h3>{{ language.my_liked }}</h3>
                    <transition-group name="fade">
                        <div key="search" class="position-relative ms-auto me-1 me-lg-2" :class="mobile ? 'w-40' : 'w-25'">
                            <input type="search" ref="search_field" v-model="search" @keyup.enter="search_page" class="form-control" :placeholder="language.type_min_3" required>
                            <button type="button" @click="search_page" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                        </div>
                        <button key="filter_data" aria-label="filter_data" data-toggle="tooltip" :title="language.heading_sort_filter" type="button" @click="filterShow" :class="{'light' : !filter_applied}" class="main-button small search_button"><i class="fas fa-15x fa-filter"></i></button>
                    </transition-group>
                </div>
                <div class="card-body">
                    <transition-group tag="div" class="pcont match-height" name="bounce">
                        <Product :lang="language" :index="index" :type="'?type=likedproducts'" :user="user" :product="product" :liked_array="liked" :can_like="true" :can_del="false" v-for="(product,index) in products" :key="product.id" />
                    </transition-group>
                </div>
                <NotFound :lang="language" :type="text" :load="loading" v-if="token == null || !products.length"></NotFound>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'filters'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="apply_filter" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.heading_sort_filter }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row match-height">
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.sort_by }}</h5>
                                            <ul class="ps-3 mb-4">
                                                <label class="radio-container small">{{ language.text_newest }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="newest">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_distance }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="distance">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_az }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-az">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_za }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-za">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_price_lth }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="price-lth">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_price_htl }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="price-htl">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                        <span class="mt-auto">
                                            <h5>{{ language.filter_by_cats }}</h5>
                                            <div class="col-12">
                                                <label class="ms-3 mb-1">{{ language.category }}</label>
                                                <v-select multiple :clearable="false" :reduce="(option) => option.value" class="v-form-control unbordered" v-model="filter_data.cat" :options="comp_cats" />
                                            </div>
                                        </span>
                                    </div>
                                    <div class="col-lg-4">
                                        <span class="order-2 order-md-1">
                                            <h5>{{ language.filter_by_type }}</h5>
                                            <ul class="ps-3 pb-3">
                                                <label class="checkbox-container small" v-for="(t,ind) in ttypes" :key="ind">{{t.label}}
                                                    <input type="checkbox" v-model="filter_data.type" :value="t.value">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                        <transition name="fade">
                                            <div class="col-12 order-1 order-md-2 d-flex flex-column justify-content-end" v-if="filter_data.cat.length > 0">
                                                <label class="ms-3 mb-1">{{ language.sub_category }}</label>
                                                <v-select multiple :clearable="false" :reduce="(option) => option.value" class="v-form-control unbordered" v-model="filter_data.subcat" :options="comp_subcats" />
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.filter_by_date }}</h5>
                                            <div class="col-12">
                                                <v-select :clearable="false" :reduce="(option) => option.value" class="v-form-control" v-model="filter_data.date_posted" :options="filter_dates" />
                                            </div>
                                        </span>
                                        <span class="mt-5 mb-3 mb-md-0">
                                            <h5>{{ language.filter_by_price }}</h5>
                                            <!-- <div class="col-12 d-flex text-nowrap align-items-center justify-content-between">
                                                <input type="range" v-model="filter_data.price" step="1" class="form-control filter-slider pe-4" :min="1" :max="max_price">
                                                <p class="mb-0">{{ price(filter_data.price || max_price) }}</p>
                                            </div> -->
                                            <Slider tooltipPosition="bottom" class="slider-blue mx-3 mx-md-0 mb-5 mb-md-0" :min="0" :format="price" :max="max_price" v-model="filter_data.price" />
                                        </span>
                                        <!-- <span class="mt-auto">
                                            <h5>{{ language.filter_by_distance }}</h5>
                                            <Slider tooltipPosition="bottom" :step="1" class="slider-blue mx-3 mx-md-0 mb-5 mb-md-0" :min="1" :max="3000" :format="f_distance" v-model="filter_data.distance" />
                                        </span> -->
                                        <!-- <span class="slider-container mt-auto">
                                            <div class="col-12 d-flex text-nowrap align-items-center justify-content-between">
                                                <input type="range" v-model="filter_data.date_posted" step="1" class="form-control filter-slider pe-4" :min="7" :max="100">
                                                <h5 class="mb-0">{{ filter_data.date_posted+' days ago' }}</h5>
                                            </div>
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button aria-label="{{language.reset}}" type="button" class="main-button light me-2" @click="reset_filter">{{language.reset}}</button>
                                <button aria-label="{{language.apply}}" type="submit" class="main-button ms-2">{{ language.apply }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
<script>
export default {
    name: 'LikedProducts',
    props: ['language', 'user', 'categories'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            products: [],
            search: '',
            loading: true,
            showModal: false,
            showInput: false,
            liked: [],
            active_chats: [],
            pids: [],
            c_page:1,
            l_count:0,
            last_item: this.isMobile() ? 4 : 1,
            search: '',
            searched: false,
            text: this.language.loading,
            filter_data: new Form({
                sort_by: 'newest',
                type: [],
                distance: 0,
                price: [0, ''],
                date_posted: 0,
                cat: [],
                subcat: [],
            }),
            filter_applied: false,
            max_price: '',
        };
    },
    created() {
        this.index()
        
        this.emitter.on("view_more", () => {
            if(this.$route.name == 'LikedProducts') {
                this.view_more()
            }
        });

        this.emitter.on("fetch_index", (id) => {
            if(this.$route.name == 'LikedProducts') {
                this.products.splice(id, 1)
            }
        });
    },
    computed: {
        comp_cats() {
            var data = []
            this.categories.forEach(cat => {
                var d = {
                    value: cat.id,
                    label: this.language[cat.text],
                }
                data.push(d)
            })
            return data
        },
        comp_subcats() {
            var data = []
            this.categories.forEach(cat => {
                if(this.filter_data.cat.includes(cat.id)) {
                    cat.sub_categories.forEach(item => {
                        var d = {
                            value: item.id,
                            label: this.language[item.text],
                        }
                        data.push(d)
                    })
                }
            })
            return data.sort()
        },
        filter_dates() {
            return [{ value:7, label: this.language.last_7_days }, { value:15, label: this.language.last_15_days }, { value:30, label: this.language.last_30_days}, { value:0, label: this.language.all_time }]
        },
        filter_price() {
            return [{ value:{min:0,max:0}, label: 'Free' }, { value:{min:0,max:1000}, label: 'Less than '+ this.price(1000) }, { value:{min:0,max:5000}, label: 'Less than '+ this.price(5000)}, { value:{min:10000,max:20000}, label: this.price(10000)+' - '+this.price(20000) }, { value:{min:20000,max:30000}, label: this.price(20000)+' - '+this.price(30000) },  { value:'Any', label: 'Any' },]
        },
        ttypes() {
            return [{ value: 1, label: this.language.trade }, { value: 2, label: this.language.sell }, { value: 3, label: this.language.free }, { value: 4, label: this.language.rent }, { value: 5, label: this.language.buy }, { value: 6, label: this.language.service }] ;
        },
    },
    watch: {
        search(newVal, oldVal) {
            if (oldVal && !newVal && this.searched) {
                this.searched = false
                this.c_page = 1
                this.products = []
                this.index()
            }
        },
    },
    mounted() {
        this.debouncedSearch = this.$debounce(this.search_page, 500);

        this.emitter.on('get-np', (e) => {
            if(e.type == 'likedproducts') {
                var index = this.pids.indexOf(e.id)
                if((index+1) == this.pids.length - 3) this.view_more()
                var obj = { n: this.pids[index+1], p: this.pids[index-1]}
                this.$nextTick(() => {
                    this.emitter.emit('set-np', obj)
                })
            }
        })
    },
    methods: {
        index() {
            this.loading = true
            this.text = this.language.loading
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            var data = {
                'string': this.search,
                'filter_data': this.filter_data,
            }
            axios.post(this.$api + "like/liked-products"+"?page="+this.c_page, data, config).then((res) => {

                if(res.data.status === false) {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.user_not_found
                    return ;
                }

                this.$Progress.finish()
                this.loading = false

                var resdata = Object.values(res.data.items.data)


                if(this.c_page != 1) {
                    this.products = [...this.products, ...resdata];
                    var items = resdata
                    this.pids = [...this.pids, ...items.map(e => e.slug)]
                }
                else {
                    this.products = resdata
                    this.pids = resdata.map(e => e.slug)
                }

                this.l_count = resdata.length
                this.text = this.language.no_products_to_display
                this.max_price = res.data.max_price
                if(!this.filter_data.price[1])
                this.filter_data.price[1] = res.data.max_price
                this.liked = res.data.liked_items
                this.active_chats = res.data.active_chats
            }).catch((e) => {
                this.$Progress.fail()
                this.loading = false
                this.text = this.language.server_error
            });
        },
        view_more() {
            if(this.l_count > 0) {
                this.c_page++
                this.index()
            }
        },
        changeShowInput() {
            this.showInput = !this.showInput
            this.$nextTick(() => {
                $('.card-header').animate({
                    scrollLeft: $('.card-header').width()
                }, 500);
            })
        },
        search_page() {
            if(this.search.length > 1){
                this.$refs.search_field.focus()
                this.searched = true
                this.c_page = 1
                this.products = []
                this.index()
            }
        },
        filterShow() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.$nextTick(() => this.showModal = 'filters')
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable') )
        },
        apply_filter() {
            this.showModal = false;
            this.c_page = 1;
            this.filter_applied = true
            this.products = []
            this.index();
        },
        reset_filter() {
            this.showModal = false;
            this.filter_data.reset()
            this.filter_data.distance = this.token ? this.user.user.distance_margin : 3000
            this.filter_data.price[0] = 0
            this.filter_data.price[1] = ''
            this.c_page = 1;
            this.filter_applied = false;
            this.products = []
            this.index();
        },
        price(val) {
            var formatter = new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                maximumFractionDigits: 0,
            });
            return formatter.format(val);
        },
    },
}
</script>