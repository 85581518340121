<template>
    <div class="row">
        <div class="col-12 text-center" :class="{ 'py-5' : !token }">
            <i class="fas" :class="load ? 'fa-spinner fa-spin fa-7x my-5' : 'fa-exclamation-circle fa-9x my-5'"></i>
            <h1 class="card-title" :class="token ? 'mt-4' : 'my-4' " v-html="type"></h1>
            <a href="javascript:;" v-if="token == null && !load && type!=this.lang.select_chat" @click="emitter.emit('showlogin')" class="main-button">{{ lang.sign_in }}</a>
            <!-- <router-link v-if="!token" class="main-button" to="/">{{ lang.sign_in }}</router-link> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    props: ['type','load','lang'],
    data() {
        return {
            token: this.isLogged()
        };
    },
    created() {
        this.emitter.on("update_user", (data) => {
            this.token = this.isLogged();
        });
        this.emitter.on("loggedOut", () => { 
            this.token = null
        });
    },

}
</script>
