<template>
    <div class="col-md-6 col-lg-4">
        <div class="transaction-box" @click="$router.push('/t/'+data.crypt_id)">
            <div>
                <div class="icon" :class="data.status == 'initiated' ? 'pending' : (data.status == 'complete' ? 'success' : 'failed')">
                    <i class="fas fa-3x" :class="data.status == 'initiated' ? 'fa-clock' : (data.status == 'complete' ? 'fa-check-double' : 'fa-times')"></i>
                </div>
            </div>
            <div class="content">
                <span class="status">
                    {{ data.status == 'initiated' ? language.transaction_pending : (data.status == 'complete' ? language.transaction_completed : language.transaction_cancelled) }}
                </span>
                <div class="price">
                    {{ data.type == 'new-ads' ? language.checkout_type_new_ads : language.checkout_type_boosting }}
                    <span>{{ price }}</span>
                </div>
                <div class="details">{{ $filters.formatDate(data.created_at) }} <img :src="gateway" alt=""></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["user", "language", "data"],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
        };
    },
    computed: {
        price() {
            var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: this.data.currency, maximumFractionDigits: 0 });
            return formatter.format(this.data.amount/100);
        },
        gateway() {
            if(this.data.gateway == 'payu') return '/admin_lte/payu_logo.png' ;
            else if(this.data.gateway == 'paypal') return '/admin_lte/paypal_logo.png' ;
            else if(this.data.gateway == 'razorpay') return '/admin_lte/razorpay_logo.png' ;
            else if(this.data.gateway == 'stripe') return '/admin_lte/stripe_logo.png' ;
        }
    },
    mounted() {
            if(!this.mobile)
            $('[data-toggle="tooltip"]').tooltip()
    },
    beforeUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
}
</script>